import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiVoucher } from 'src/api/apiVoucher';
import { logErrorEvent } from 'src/logging/loggingActions';
import { showErrorNotification } from 'src/redux/app/appSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { getRedeemedVouchers, getRedeemedVouchersSuccess } from 'src/redux/order/voucherSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { RedeemedVouchersResponse } from 'src/types/voucher/RedeemedVoucherResponse';


const { getCustomerExternalId } = customerInfoSelectors;

export function* getRedeemedVouchersSaga() {
  try {
    const customerExternalId = yield select(getCustomerExternalId);
    const apiVoucher: ApiVoucher = yield getContext(SagaContextItem.apiVoucher);
    const response: RedeemedVouchersResponse = yield call(apiVoucher.getRedeemedVouchersHistory, customerExternalId);

    yield put(getRedeemedVouchersSuccess(response));
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not fetch redeemed vouchers', err }));
    yield put(showErrorNotification('voucher.couldntGetRedeemedVouchers'));
  }
}

export default function* getRedeemedVouchersWatcher() {
  yield takeLatest(getRedeemedVouchers.type, getRedeemedVouchersSaga);
}
