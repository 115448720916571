import { call, put } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import config from 'src/config';
import {
  LoginMethod,
  openModal,
  setLoginMethod
} from 'src/redux/app/appSlice';
import {
  birthDayCheckConfirmed,
  createCustomerSuccess,
  setBirthDayCheckConfirmed,
  setSelectedCustomerLocalId,
} from 'src/redux/customer/customerInfoSlice';
import {
  authenticateCustomer,
  resetCustomerPinState,
  setPinCheckModalNextStep
} from 'src/redux/customer/CustomerPinSlice';
import {
  addOriginalOrderErrors,
  FailedRecreateOrderStep,
  RecreateOrderError,
} from 'src/redux/recreateOrder/recreateOrderSlice';
import { buildInvoiceAddressFromCustomer, findCustomerByExternalId } from 'src/sagas/customer/fetchCustomerByNumberSaga';
import { handleBlockedCustomer } from 'src/sagas/customer/handleBlockedCustomer';
import { processAndGetOffer } from 'src/sagas/offer/getOffer';
import {
  DeliveryAddress,
  DeliveryAddressResponse,
  DeliveryAddressType
} from 'src/types/customer/address';
import { Customer } from 'src/types/customer/customer';
import { CustomerResponse } from 'src/types/customer/CustomerResponse';
import { Modals } from 'src/types/Modals';
import { extractDetails } from 'src/utils/errorStatusChecks';
import { isBirthdayCheckConfirmed } from 'src/utils/lastBirthdayCheck';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';
import isCustomerLocked from 'src/utils/validators/isCustomerLocked';


export function* fillOriginalOrderCustomer(customer: CustomerResponse, deliveryAddress: DeliveryAddressResponse) {
  if (customer.crmPartnerId) {
    yield call(loadExistingOrderCustomer, customer.crmPartnerId, false);
    return;
  }

  try {
    const newCustomer: Customer = {
      firstName: customer.firstName,
      lastName: customer.lastName,
      billingAddress: {
        zipCode: customer.zipCode,
        street: customer.street,
        streetNumber: customer.streetNumber,
        countryCode: customer.countryCode,
        city: customer.city,
        careOf: customer.careOf,
        addressAddition: customer.addressAddition,
      },
      salutation: customer.salutation,
      dateOfBirth: customer.dateOfBirth,
      id: '',
      externalCustomerId: '',
      phoneNumber: customer.phone,
      phoneNumbers: [],
      deliveryAddresses: [],
      origin: '',
      consents: customer.consents,
    };
    const deliveryAddresses: DeliveryAddress[] = [buildInvoiceAddressFromCustomer(newCustomer)];
    if (!isBillingAddress(deliveryAddress, customer)) {
      deliveryAddresses.push({ ...deliveryAddress, id: deliveryAddress.id || uuidv4() });
    }
    const customerData = { ...newCustomer, deliveryAddresses };
    const localCustomerId = localCustomerStorage.saveCustomer(customerData);
    yield put(setSelectedCustomerLocalId(localCustomerId));
    localCustomerStorage.setCustomerWasAskedAboutConsents(localCustomerId, true);
    yield put(createCustomerSuccess(customerData));
    yield put(setBirthDayCheckConfirmed());

    yield call(processAndGetOffer);
  } catch (err) {
    const error: RecreateOrderError = {
      step: FailedRecreateOrderStep.LOAD_CUSTOMER,
      detail: `Neukunde konnte nicht geladen werden: ${extractDetails(err)}`,
    };
    yield put(addOriginalOrderErrors(error));
  }
}

function isBillingAddress(address: DeliveryAddressResponse, customer: CustomerResponse) {
  return address.type === DeliveryAddressType.Postal &&
    address.street === customer.street &&
    address.streetNumber === customer.streetNumber &&
    trimStringOrNull(address.addressAddition) === trimStringOrNull(customer.addressAddition) &&
    trimStringOrNull(address.careOf) === trimStringOrNull(customer.careOf) &&
    address.city === customer.city &&
    address.zipCode === customer.zipCode &&
    address.firstName === customer.firstName &&
    address.lastName === customer.lastName &&
    address.salutation === customer.salutation;
}

export function* loadExistingOrderCustomer(externalCustomerId: string, skipAuthenticationCheck: boolean) {
  try {
    const existingCustomer = yield call(findCustomerByExternalId, externalCustomerId);
    if (isCustomerLocked(existingCustomer)) {
      yield call(handleBlockedCustomer, existingCustomer);
      return;
    }
    const customer = reformatBirthdayInResponse(existingCustomer);
    yield put(resetCustomerPinState(customer));
    if (skipAuthenticationCheck) {
      yield put(authenticateCustomer());
    } else {
      yield put(setLoginMethod(LoginMethod.LOAD_EXISTING_ORDER_CUSTOMER));
      if (existingCustomer.pinChallenge !== undefined && config.env.enableCustomerPinFeature) {
        yield put(setPinCheckModalNextStep());
        yield put(openModal(Modals.pinChallengeModal));
      } else {
        if(isBirthdayCheckConfirmed(customer.externalCustomerId)){
          yield put(birthDayCheckConfirmed());
        } else {
          yield put(openModal(Modals.birthdayCheck));
        }
      }
    }

    yield call(processAndGetOffer);
  } catch (err) {
    const error: RecreateOrderError = {
      step: FailedRecreateOrderStep.LOAD_CUSTOMER,
      detail: `Kunde ${externalCustomerId} konnte nicht authentifiziert werden: ${extractDetails(err)}`,
    };
    yield put(addOriginalOrderErrors(error));
  }
}
