import { DeliveryAddressResponse } from 'src/types/customer/address';
import { CustomerResponse } from 'src/types/customer/CustomerResponse';
import { Dimension } from 'src/types/offer/Basket';
import { DeliveryResponse } from 'src/types/offer/DeliveryResponse';
import { ItemVoucher } from 'src/types/offer/OrderItem';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { CrossSellInfo } from 'src/types/product/CrossSell';
import { Currency } from 'src/types/product/Currency';
import { DeliveryTimeMessage } from 'src/types/product/DeliveryTime';
import { Salesdriver } from 'src/types/product/product';
import { ServiceVoucher } from 'src/types/voucher/ServiceVoucher';
import { VoucherOrderResponse } from 'src/types/voucher/VoucherOrderResponse';


export interface InstallmentPlan {
  installmentCount: number;
  installment: number;
  finalInstallment: number;
  total: number;
  interestRate: number;
  totalInterest: number;
}

export interface InstallmentsPayment extends InstallmentPlan, TransferPayment {
  method: PaymentMethod.installments;
  dueDay: number;
}

export interface InstallmentsFactoringPayment extends InstallmentPlan, TransferPayment {
  method: PaymentMethod.installmentsFactoring;
  calculationId: string;
  channel: string;
}

export enum CountryCode {
  de = 'DE',
  at = 'AT',
  ch = 'CH',
}

export enum CountryCodeName {
  DE = 'Deutschland',
  AT = 'Österreich',
}

interface ItemPrice {
  label?: string;
  value: number;
  date?: string;
}

interface ItemVariant {
  dimensions?: Dimension[];
  sku: string;
  price: ItemPrice;
  deliveryTime?: DeliveryTimeMessage;
}

interface ProductName {
  long: string;
}

export interface ItemResponse {
  id: string;
  baseProductNo?: string;
  brand?: {brandName: string};
  categoryPath?: string;
  wgh?: string;
  itemSource?: string;
  coupon?: string;
  salesdrivers?: Salesdriver[];
  name: ProductName;
  variant: ItemVariant;
  quantity: number;
  crossSell?: CrossSellInfo;
  source?: CrossSellInfo;
  weeeTakeBackEnabled?: boolean;
  createdAt?: string;
  voucher?: ItemVoucher;
}

export type PaymentResponse =
  | InvoicePayment
  | PaypalPayment
  | DirectDebitPayment
  | InstallmentsPayment
  | InstallmentsFactoringPayment
  | CashOnDelivery
  | CreditCard;

export interface InvoicePayment {
  method: PaymentMethod.invoice;
}

export interface PaypalPayment {
  method: PaymentMethod.paypal;
  transactionId: string;
  merchantAccountId: string;
}

interface TransferPayment {
  accountHolder: string;
  iban: string;
}

export interface DirectDebitPayment extends TransferPayment {
  method: PaymentMethod.directDebit;
}

export interface CashOnDelivery {
  method: PaymentMethod.cashOnDelivery;
}

export interface CreditCard {
  method: PaymentMethod.creditCard;
  pspReference: string;
  merchantAccountCode: string;
}

export enum Status {
  created = 'CREATED',
  submitted = 'SUBMITTED',
  accepted = 'ACCEPTED',
  rejected = 'REJECTED',
  failed = 'FAILED',
  cancelled = 'CANCELLED',
  erpExportInProgress = 'ERP_EXPORT_IN_PROGRESS',
  recreatedOrder = 'RECREATED_ORDER',
}

export interface SimpleStatus {
  status: Status.created | Status.submitted | Status.accepted | Status.erpExportInProgress | Status.recreatedOrder;
  timestamp: string;
  backofficeAgentName?: string;
}

export interface RejectedStatus {
  status: Status.rejected;
  timestamp: string;
  rejectionReason?: RejectionReason;
}

export interface FailedStatus {
  status: Status.failed;
  timestamp: string;
  failureReason?: FailureReason;
}

export enum CancelledBy {
  system = 'SYSTEM',
  agent = 'AGENT'
}

export interface CancelledStatus {
  status: Status.cancelled;
  timestamp: string;
  cancelledBy: CancelledBy;
  cancellationReason?: string;
}

export interface RecreatedOrderStatus {
  status: Status.recreatedOrder;
  timestamp: string;
  originalOrderId: string;
  newOrderId: string;
}

export type StatusHistoryEntry = SimpleStatus | RejectedStatus | FailedStatus | CancelledStatus | RecreatedOrderStatus;

export interface StatusHistoryResponse {
  statusHistory: StatusHistoryEntry[];
}

export enum ProcessingSystem {
  CRM = 'CRM',
  ERP = 'ERP'
}

export enum OrderType {
  GUEST_ORDER = 'GUEST_ORDER',
  EXISTING_CUSTOMER_ORDER = 'EXISTING_CUSTOMER_ORDER',
  NEW_CUSTOMER_ORDER = 'NEW_CUSTOMER_ORDER'
}

export enum OrderSource {
  SHOP = 'SHOP',
  SHOP_AUTOMATIC = 'SHOP_AUTOMATIC',
  BACKOFFICE = 'BACKOFFICE',
  MOBILE = 'MOBILE',
  NEXT_APP = 'NEXT_APP',
  PHONE_BOT = 'PHONE_BOT',
  WAITLIST_SERVICE = 'WAITLIST_SERVICE',
  SUBSCRIPTION_SERVICE = 'SUBSCRIPTION_SERVICE'
}

export interface Order {
  id: string;
  customer: CustomerResponse;
  items: ItemResponse[];
  currency: Currency;
  totalPrice: number;
  payment: PaymentResponse;
  salesOffice?: string;
  salesChannel?: string;
  delivery: DeliveryResponse;
  deliveryAddress: DeliveryAddressResponse;
  createdAt: string;
  statusHistory: StatusHistoryEntry[];
  voucher?: VoucherOrderResponse;
  vouchers?: VoucherOrderResponse[];
  sourceChannel?: SourceChannels;
  processingSystem: ProcessingSystem;
  serviceVoucher?: ServiceVoucher;
  orderType: OrderType;
  source: OrderSource;
}

export type RejectionReason =
  | CustomerModificationRequired
  | CrmErrorCodeReason
  | ExceptionReason
  | UnexpectedCrmResponseReason;

export type FailureReason = UniversumBonitaetCheckFailed | BoniversumCheckFailed;

export enum RejectionReasonType {
  CrmErrorCode = 'CrmErrorCode',
  CustomerModificationRequired = 'CustomerModificationRequired',
  Exception = 'Exception',
  UnexpectedCrmResponse = 'UnexpectedCrmResponse',
  ErpRejectionReason = 'ErpRejectionReason'
}

export enum FailureReasonType {
  UniversumBonitaetCheckFailed = 'UniversumBonitaetCheckFailed',
  BoniversumCheckFailed = 'BoniversumCheckFailed'
}

export interface CustomerModificationRequired {
  type: RejectionReasonType.CustomerModificationRequired;
  fields: DataField[];
  crmCustomerId: string;
  diffs?: DeviantFiled[];
}

export interface CrmErrorCodeReason {
  type: RejectionReasonType.CrmErrorCode;
  errorCode: string | null;
  errorMessage: string | null;
  sapMessages: string[] | null;
}

export interface ExceptionReason {
  type: RejectionReasonType.Exception;
  exceptionClass: string;
  message: string | null;
  stackTrace: string[];
}

export interface UnexpectedCrmResponseReason {
  type: RejectionReasonType.UnexpectedCrmResponse;
  message: string;
  response: string;
}

export interface UniversumBonitaetCheckFailed {
  type: FailureReasonType.UniversumBonitaetCheckFailed;
  correlationId: string;
}

export interface BoniversumCheckFailed {
  type: FailureReasonType.BoniversumCheckFailed;
  reason: BoniversumCheckFailedDetails;
}

export interface BoniversumCheckFailedDetails {
  key: string;
  detail: string;
  type: string;
}

export interface DeviantFiled {
  dataField: string[];
  dplValue?: string;
  crmValue?: string;
}
export interface DataField {
  type: string;
  name: string;
}
