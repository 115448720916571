// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { put } from 'redux-saga/effects';

import { showErrorNotificationWithBackendMessage } from 'src/redux/app/appSlice';
import { fetchInflightOrderFailure } from 'src/redux/inflightOrder/inflightOrderSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleFetchInflightOrderFailure(error: any) {
  yield put(fetchInflightOrderFailure({
    title: error.response?.data?.title || '',
    detail: error.response?.data?.detail || '',
  }));
  yield put(showErrorNotificationWithBackendMessage(error.response?.data?.detail));
}
