import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { subscriptionHistorySelector } from 'src/redux/customer/selectors/subscriptionHistorySelectors';
import {
  fetchSubscriptionHistory,
  fetchSubscriptionHistorySuccess,
  fetchSubscriptionHistoryError,
  setSubscriptionSearchKeyword,
  setSelectedSubscriptionStatus,
} from 'src/redux/customer/subscriptionHistorySlice';
import { checkAndLoadCustomer } from 'src/sagas/utils/checkAndLoadCustomer';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { SubscriptionHistoryResponse } from 'src/types/subscription/SubscriptionHistoryResponse';
import { SubscriptionStatusRequest } from 'src/types/subscription/SubscriptionsOverviewRequest';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';


export function* fetchSubscriptionHistorySaga() {
  const criteria = yield select(subscriptionHistorySelector.getSubscriptionsSearchCriteria);
  const externalCustomerId = yield select(subscriptionHistorySelector.getExternalCustomerId);

  try {
    const apiCallCenter: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);

    const response: SubscriptionHistoryResponse = yield call(
      apiCallCenter.getFilteredSubscriptionsProducts,
      externalCustomerId,
      trimStringOrNull(criteria.keyword),
      criteria.status === SubscriptionStatusRequest.ALL ? undefined : criteria.status,
      criteria.keywordType,
      criteria.limit
    );

    yield put(fetchSubscriptionHistorySuccess(response));

    yield call(checkAndLoadCustomer, externalCustomerId);
  } catch (err) {
    const errorCode = (err && err.response && err.response.status) || 400;
    yield put(fetchSubscriptionHistoryError(errorCode));
    yield call(
      logErrorAndShowNotification,
      `Couldn't fetch subscriptions products for customer ${externalCustomerId}, search criteria: ${JSON.stringify(criteria)}`,
      'Hoppla... Abonnementprodukte konnten nicht abgerufen werden.',
      err,
    );
  }
}

export default function* fetchSubscriptionHistoryWatcher() {
  yield takeLatest(fetchSubscriptionHistory.type, fetchSubscriptionHistorySaga);
  yield takeLatest(setSelectedSubscriptionStatus.type, fetchSubscriptionHistorySaga);
  yield takeLatest(setSubscriptionSearchKeyword.type, fetchSubscriptionHistorySaga);
}
