import { Salutation } from 'src/types/customer/Salutation';


export interface AddressValidationResponseItem {
  salutation?: Salutation;
  firstName?: string;
  lastName?: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  streetFlag?: StreetFlagType,
  cityFlag?: CityFlagType,
  housenoFlag?: HousenoFlagType,
  zipCodeFlag?: ZipCodeFlagType
}

export interface AddressValidationResponse {
  completeAddresses: AddressValidationResponseItem[],
  partialAddresses: AddressValidationResponseItem[],
}

export enum StreetFlagType {
  VERIFIED = 'VERIFIED',
  NORMALIZED = 'NORMALIZED',
  ABBREVIATION = 'ABBREVIATION',
  FULL_TITLE = 'FULL_TITLE',
  CORRECTED = 'CORRECTED',
  DUBIOUS = 'DUBIOUS',
  RENAMED = 'RENAMED',
  WITHOUT = 'WITHOUT',
  EMPTY = 'EMPTY',
  NOT_FOUND = 'NOT_FOUND',
  PACKSTATION = 'PACKSTATION'
}

export enum CityFlagType {
  VERIFIED = 'VERIFIED',
  CORRECTED = 'CORRECTED',
  DUBIOUS = 'DUBIOUS',
  RENAMED = 'RENAMED',
  WITHOUT = 'WITHOUT',
  EMPTY = 'EMPTY',
  NORMALIZED = 'NORMALIZED',
  NOT_FOUND = 'NOT_FOUND'
}

export enum HousenoFlagType {
  VERIFIED = 'VERIFIED', RENAMED = 'RENAMED', WITHOUT = 'WITHOUT', EMPTY = 'EMPTY', NOT_FOUND = 'NOT_FOUND'
}

export enum ZipCodeFlagType {
  VERIFIED = 'VERIFIED',
  CORRECTED = 'CORRECTED',
  RENAMED = 'RENAMED',
  PARTIALLY = 'PARTIALLY',
  WITHOUT = 'WITHOUT',
  EMPTY = 'EMPTY',
  NOT_FOUND = 'NOT_FOUND'
}


export function isPartialZipCode(flag: ZipCodeFlagType | undefined): Boolean {
  let partialZipCode = false;
  if (flag === ZipCodeFlagType.PARTIALLY) {
    partialZipCode = true;
  }
  return partialZipCode;
}

export function badZipCodeFlag(flag: ZipCodeFlagType | undefined) {
  if (flag === undefined){
    return false;
  }
  return !(flag === ZipCodeFlagType.PARTIALLY || flag === ZipCodeFlagType.VERIFIED || flag === ZipCodeFlagType.CORRECTED || flag === ZipCodeFlagType.RENAMED);
}

export function badCityFlag(flag: CityFlagType | undefined) {
  if (flag === undefined){
    return false;
  }
  return !(flag === CityFlagType.VERIFIED || flag === CityFlagType.CORRECTED || flag === CityFlagType.DUBIOUS || flag === CityFlagType.RENAMED);
}

export function badHousenoFlag(flag: HousenoFlagType | undefined) {
  if (flag === undefined){
    return false;
  }
  return !(flag === HousenoFlagType.VERIFIED || flag === HousenoFlagType.RENAMED);
}

export function badStreetFlag(flag: StreetFlagType | undefined) {
  if (flag === undefined){
    return false;
  }
  return !(flag === StreetFlagType.VERIFIED ||
    flag === StreetFlagType.NORMALIZED ||
    flag === StreetFlagType.ABBREVIATION ||
    flag === StreetFlagType.FULL_TITLE ||
    flag === StreetFlagType.CORRECTED ||
    flag === StreetFlagType.DUBIOUS ||
    flag === StreetFlagType.RENAMED);
}
