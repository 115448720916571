import dayjs from 'dayjs';

import { SubscriptionsOverviewFetchFilters } from 'src/subscriptionProductManagement/redux/subscriptionManagementSlice';
import { SortBy, SortingMode } from 'src/subscriptionProductManagement/utils/subscriptionProductManagementUtils';
import { SubscriptionCancellationReason } from 'src/types/subscription/SubscriptionHistoryResponse';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';


export enum SubscriptionStatusRequest {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  DELAY_IN_DELIVERY = 'DELAY_IN_DELIVERY',
  CANCELED = 'CANCELED',
}
export enum SearchDatePeriod {
  YESTERDAY = 'YESTERDAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_SIX_MONTHS = 'LAST_SIX_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM = 'CUSTOM',
}
export enum SearchDateType {
  CREATION_DATE = 'CREATION_DATE',
  NEXT_DELIVERY_DATE = 'NEXT_DELIVERY_DATE',
  PREVIOUS_EDIT_DATE = 'PREVIOUS_EDIT_DATE',
  NONE = 'NONE'
}

export enum KeywordType {
  DELIVERY_PRODUCT_NO = 'DELIVERY_PRODUCT_NO',
  SUBSCRIPTION_NO = 'SUBSCRIPTION_NO',
  CUSTOMER_NO = 'CUSTOMER_NO',
}

export enum FetchSubscriptionsKeywordCriteriaType {
  CONTRACT_NUMBER = 'CONTRACT_NUMBER',
  SUBSCRIPTION_PRODUCT_NO = 'SUBSCRIPTION_PRODUCT_NO',
  DELIVERY_PRODUCT_NO = 'DELIVERY_PRODUCT_NO',
}

export interface SubscriptionsOverviewRequest {
  page: number;
  size: number;
  total?: number;
  keywords?: string;
  status?: SubscriptionStatusRequest;
  searchFrom?: string;
  searchTo?: string;
  searchDateType?: SearchDateType;
  keywordType?: KeywordType;
  sortBy?: SortBy;
  sortingMode?: SortingMode;
  cancellationReason?: SubscriptionCancellationReason;
}

export const createSubscriptionsOverviewFetchRequest = (
  page: number,
  size: number,
  filters: SubscriptionsOverviewFetchFilters,
  total: number,
): SubscriptionsOverviewRequest => {
  const keyword = trimStringOrNull(filters.keywords);

  return {
    size,
    page,
    total: total || undefined,
    status: filters.status,
    searchFrom: formatDate(filters.searchFrom),
    searchTo: formatDate(filters.searchTo),
    keywords: keyword,
    searchDateType: filters.searchDateType,
    keywordType: keyword ? filters.keywordType : undefined,
    sortBy: filters.sortBy || undefined,
    sortingMode: filters.sortBy ? filters.sortingMode : undefined,
    cancellationReason: filters.status === SubscriptionStatusRequest.CANCELED ? filters.cancellationReason : undefined,
  };
};

const formatDate = (date?: Date): string | undefined =>
  date ? dayjs(date).format('YYYY-MM-DD') : undefined;
