import {
  createAction,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { OverviewState, SubscriptionProductLink } from 'src/subscriptionLinksManagement/types/subscriptionLinksManagement';
import {
  EditSubscriptionProductLinkRequest,
  FilterSearchConstraint,
  FilterType,
  SubscriptionLinksStatusRequest,
  SubscriptionProductLinkRequestWrapper
} from 'src/subscriptionLinksManagement/types/SubscriptionProductLinkRequest';
import { SubscriptionProductLinkManagementModals } from 'src/subscriptionLinksManagement/utils/modals';
import { ProductDetails } from 'src/types/product/product';

import { SearchSubscriptionProductLinkResponse } from '../types/SearchSubscriptionProductLinkResponse';


export interface SubscriptionLinksManagementState {
  overview: OverviewState;
  editLink: EditLinkState;
  createLink: CreateLinkState;
  modals: SubscriptionProductLinkManagementModals[];
  bnrSearch: BNRSearch;
}

export interface CreateLinkState {
  loading: boolean;
  productData?: SubscriptionProductLink;
}
export interface EditLinkState {
  loading: boolean;
  linkData?: SubscriptionProductLink;
  productDetails?: ProductDetails;
  loadingError: boolean;
}

export interface BNRSearch {
  loading: boolean;
  searchQuery?: string;
  productDetails?: ProductDetails;
}
export interface FilterPayload {
  searchInputType?: FilterType;
  filterSearchConstraint?: FilterSearchConstraint;
  status?: SubscriptionLinksStatusRequest;
}
export const initialSubscriptionLinksManagementState: SubscriptionLinksManagementState = {
  overview: {
    initialLoading: false,
    onScrollLoading: false,
    items: [],
    page: 0,
    size: 10,
    total: 0,
    hasNextPage: false,
    filters: {
      status: SubscriptionLinksStatusRequest.ALL,
      activeFilters: [],
    }
  },
  editLink: {
    loading: false,
    loadingError: false,
  },
  createLink: {
    loading: false
  },
  bnrSearch: {
    loading: false
  },
  modals: []
};

export const searchSubscriptionProductLinksAction = createAction('subscriptionLinksManagement/FETCH_SUBSCRIPTION_PRODUCT_LINKS');

export const createSubscriptionProductLinkAction = createAction<SubscriptionProductLinkRequestWrapper>('subscriptionLinksManagement/CREATE_SUBSCRIPTION_PRODUCT_LINK');
export type CreateSubscriptionProductLinkAction = ReturnType<typeof createSubscriptionProductLinkAction>;

export const initSubscriptionProductLinkAction = createAction<SubscriptionProductLink>('subscriptionLinksManagement/INIT_SUBSCRIPTION_PRODUCT_LINK');
export type InitSubscriptionProductLinkAction = ReturnType<typeof initSubscriptionProductLinkAction>;

export const editSubscriptionProductLinkAction = createAction<EditSubscriptionProductLinkRequest>('subscriptionLinksManagement/EDIT_SUBSCRIPTION_PRODUCT_LINK');
export type EditSubscriptionProductLinkAction = ReturnType<typeof editSubscriptionProductLinkAction>;

export interface SearchByBnrRequest {
  bnr: string;
  forEdit?: boolean;
}

export const searchByBnrAction = createAction<SearchByBnrRequest>('subscriptionLinksManagement/SEARCH_BY_BNR');
export type SearchByBnrAction = ReturnType<typeof searchByBnrAction>;

const subscriptionLinksManagementSlice = createSlice({
  name: 'subscriptionLinksManagement',
  initialState: initialSubscriptionLinksManagementState,
  reducers: {
    searchSubscriptionProductLinksSuccess(state,{ payload }: PayloadAction<SearchSubscriptionProductLinkResponse>) {
      state.overview.initialLoading = false;
      state.overview.onScrollLoading = false;
      const { result, total, hasNextPage, page } = payload;
      state.overview.items = state.overview.page === 0 ? result : [...state.overview.items, ...result];
      state.overview.page = page;
      state.overview.total = total;
      state.overview.hasNextPage = hasNextPage;
    },
    searchSubscriptionProductLinksFailed(state) {
      state.overview.onScrollLoading = false;
      state.overview.initialLoading = false;
      state.overview.total = 0;
      state.overview.hasNextPage = false;
      state.overview.items = [];
    },
    createSubscriptionProductLinkSuccess(state,{ payload }: PayloadAction<SubscriptionProductLink[]>) {
      state.createLink.loading = false;
      state.overview.items = [...payload, ...state.overview.items];
    },
    createSubscriptionProductLinkFailed(state) {
      state.createLink.loading = false;
    },
    initSubscriptionProductLinkEdit(state, { payload }: PayloadAction<SubscriptionProductLink>) {
      state.editLink.linkData = payload;
    },
    initSubscriptionProductLinkEditSuccess(state, { payload }: PayloadAction<{linkData: SubscriptionProductLink, productDetails: ProductDetails}>) {
      state.editLink.linkData = payload.linkData;
      state.editLink.productDetails = payload.productDetails;
    },
    resetInitSubscriptionProductLinkEdit(state) {
      state.editLink = initialSubscriptionLinksManagementState.editLink;
    },
    editSubscriptionProductLinkSuccess(state,{ payload }: PayloadAction<SubscriptionProductLink>) {
      state.editLink.loading = false;
      state.overview.items = state.overview.items.map(item => item.id === payload.id ? payload : item);
    },
    editSubscriptionProductLinkFailed(state) {
      state.editLink.loading = false;
    },
    searchByBnrSuccess(state, { payload }: PayloadAction<ProductDetails>) {
      state.bnrSearch.productDetails = payload;
      state.bnrSearch.searchQuery = payload.baseProductNo;
      state.bnrSearch.loading = false;
    },
    resetSearchByBnr(state) {
      state.bnrSearch = initialSubscriptionLinksManagementState.bnrSearch;
    },
    searchByBnrForEditSuccess(state, { payload }: PayloadAction<ProductDetails>) {
      state.editLink.productDetails = payload;
      state.bnrSearch.loading = false;
      state.editLink.loadingError = false;
    },
    searchByBnrForEditFail(state) {
      state.editLink.loadingError = true;
      state.bnrSearch.loading = false;
    },
    openModal(state, { payload }: PayloadAction<SubscriptionProductLinkManagementModals>) {
      state.modals = [...state.modals, payload];
    },
    closeModal(state, { payload }: PayloadAction<SubscriptionProductLinkManagementModals>) {
      state.modals = state.modals.filter(it => it !== payload);
    },
    setFilter(
      state,
      { payload }: PayloadAction<FilterPayload>,
    ) {
      state.overview.page = 0;
      state.overview.initialLoading = true;
      state.overview.onScrollLoading = false;
      const { filterSearchConstraint, searchInputType, status } = payload;
      if (filterSearchConstraint) {
        const filterIndex = state.overview.filters.activeFilters.findIndex(filter => filter.type === payload?.filterSearchConstraint?.type);
        if (filterIndex === -1 && Object.keys(filterSearchConstraint).length !== 1) {
          state.overview.filters.activeFilters.push(filterSearchConstraint);
        } else {
          if (Object.keys(filterSearchConstraint).length === 1) {
            state.overview.filters.activeFilters = state.overview.filters.activeFilters.filter(filter => filter.type !== payload.filterSearchConstraint?.type);
          } else {
            state.overview.filters.activeFilters[filterIndex] = filterSearchConstraint;
          }
        }
      }
      state.overview.filters = { ...state.overview.filters,
        filterType: searchInputType ?? state.overview.filters.filterType,
        status: status ?? state.overview.filters.status,
      };
    },
    setDisplayedFilter(state, { payload }: PayloadAction<FilterType>) {
      state.overview.filters.displayedFilter = payload;
    },
    clearFilters(state) {
      state.overview.page = 0;
      state.overview.initialLoading = true;
      state.overview.onScrollLoading = false;
      state.overview.filters = {
        filterType: undefined,
        activeFilters: [],
        status: state.overview.filters.status
      };
    },
    clearFilterConstraint(state,{ payload }: PayloadAction<FilterType>) {
      state.overview.page = 0;
      state.overview.initialLoading = true;
      state.overview.onScrollLoading = false;
      state.overview.filters.activeFilters = state.overview.filters.activeFilters.filter(filter => filter.type !== payload);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(searchSubscriptionProductLinksAction, state => {
        if (state.overview.page === 0) {
          state.overview.initialLoading = true;
          state.overview.onScrollLoading = false;
        } else {
          state.overview.initialLoading = false;
          state.overview.onScrollLoading = true;
        }
      })
      .addCase(createSubscriptionProductLinkAction, state => {
        state.createLink.loading = true;
      })
      .addCase(editSubscriptionProductLinkAction, state => {
        state.editLink.loading = true;
      })
      .addCase(searchByBnrAction, state => {
        state.bnrSearch.loading = true;
      });
  }
});

export const {
  searchSubscriptionProductLinksSuccess,
  searchSubscriptionProductLinksFailed,
  createSubscriptionProductLinkSuccess,
  createSubscriptionProductLinkFailed,
  initSubscriptionProductLinkEdit,
  editSubscriptionProductLinkSuccess,
  editSubscriptionProductLinkFailed,
  searchByBnrSuccess,
  resetSearchByBnr,
  openModal,
  closeModal,
  initSubscriptionProductLinkEditSuccess,
  resetInitSubscriptionProductLinkEdit,
  setFilter,
  setDisplayedFilter,
  clearFilters,
  clearFilterConstraint,
  searchByBnrForEditSuccess,
  searchByBnrForEditFail
} = subscriptionLinksManagementSlice.actions;

export const subscriptionLinksManagementReducer = subscriptionLinksManagementSlice.reducer;
