if (!window.IntersectionObserver) {
  require('intersection-observer');
}

import smoothscroll from 'smoothscroll-polyfill';


smoothscroll.polyfill();

if (window.Intl) {
  // `Intl` exists, but it doesn't have the data we need, so load the polyfills we need
  if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/locale-data/de');
  }
} else {
  window.Intl = require('intl');
  require('intl/locale-data/jsonp/de-DE');
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/de');
}
