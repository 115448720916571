import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import {
  ResubmitOrder,
  resubmitOrder,
  resubmitOrderFailed,
  resubmitOrderSuccess,
} from 'src/OrderDisplay/redux/orderDisplaySlice';
import { showErrorNotificationWithBackendMessage, showSuccessNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';


export function* resubmitOrderSaga(action: ResubmitOrder) {
  try {
    const apiOrder = yield getContext(SagaContextItem.apiOrder);
    yield call(apiOrder.updateOrder, action.payload);
    const response = yield call(apiOrder.resubmitOrder, action.payload.id);

    yield put(resubmitOrderSuccess(response.statusHistory));
    yield put(showSuccessNotification('notification.resubmitOrder.success'));
  } catch (err) {
    yield put(showErrorNotificationWithBackendMessage(err?.response?.data?.detail ?? 'Vorgang fehlgeschlagen.'));
    yield put(logErrorEvent({ message: 'could not resubmit order', err }));

    yield put(resubmitOrderFailed());
  }
}

export default function* resubmitOrderWatcher() {
  yield takeLatest(resubmitOrder.type, resubmitOrderSaga);
}
