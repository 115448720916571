import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const customerSuggestionsSelector = createSelectors({
  getSuggestionCustomers({ customer: { suggestions } }: RootStateType) {
    return suggestions.customers;
  },
  getFormCustomer({ customer: { suggestions } }: RootStateType) {
    return suggestions.customerFormValues;
  },
});
