import dayjs from 'dayjs';
import { IntlFormatters } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';

import { CountryCode } from 'src/api/orderTypes';
import { DEFAULT_DISPLAY_DATE_FORMAT } from 'src/constants/date';
import { DeliveryAddressType } from 'src/types/customer/address';
import { PostalAddress } from 'src/types/customer/AddressType';
import { Salutation } from 'src/types/customer/Salutation';
import { eighteenYearsAgo } from 'src/utils/customer/dateOfBirthUtils';
import {
  AT_ZIP_CODE_REGEX,
  CRM_EMAIL_REGEX,
  DATE_REGEX,
  DE_ZIP_CODE_REGEX,
  INVALID_ADDRESS_CHARACTERS_REGEX,
  NOT_EMAIL_REGEX,
  NOT_LETTERS_REGEX,
  PACKSTATION_STREET_REGEX,
  PHONE_NUMBER_REGEX,
  POST_NUMBER_REGEX,
  SHOULD_START_WITH_NUMBER_REGEX,
} from 'src/utils/validators/yupSchema';


export enum CustomerFormField {
  id = 'id',
  salutation = 'salutation',
  firstName = 'firstName',
  lastName = 'lastName',
  dateOfBirth = 'dateOfBirth',
  phoneNumber = 'phoneNumber',
  email = 'email',
  billingAddressZipCode = 'billingAddressZipCode',
  billingAddressCity = 'billingAddressCity',
  billingAddressCountryCode = 'billingAddressCountryCode',
  billingAddressCareOf = 'billingAddressCareOf',
  billingAddressStreet = 'billingAddressStreet',
  billingAddressStreetNumber = 'billingAddressStreetNumber',
  billingAddressAddressAddition = 'billingAddressAddressAddition',

  skipDoubleCheck = 'skipDoubleCheck',
  isValidAddress = 'isValidAddress',
}

export const addressFields = [
  CustomerFormField.billingAddressZipCode,
  CustomerFormField.billingAddressCity,
  CustomerFormField.billingAddressCountryCode,
  CustomerFormField.billingAddressCareOf,
  CustomerFormField.billingAddressStreet,
  CustomerFormField.billingAddressStreetNumber,
  CustomerFormField.billingAddressAddressAddition,
];

export interface CustomerFormData {
  [CustomerFormField.id]: string;
  [CustomerFormField.salutation]?: Salutation;
  [CustomerFormField.firstName]: string;
  [CustomerFormField.lastName]: string;
  [CustomerFormField.dateOfBirth]?: string;
  [CustomerFormField.email]?: string;
  [CustomerFormField.phoneNumber]?: string;
  [CustomerFormField.billingAddressZipCode]: string;
  [CustomerFormField.billingAddressCity]: string;
  [CustomerFormField.billingAddressCountryCode]: CountryCode;
  [CustomerFormField.billingAddressStreet]: string;
  [CustomerFormField.billingAddressStreetNumber]: string;
  [CustomerFormField.billingAddressAddressAddition]?: string;
  [CustomerFormField.billingAddressCareOf]?: string;
  [CustomerFormField.skipDoubleCheck]: boolean;
  [CustomerFormField.isValidAddress]: boolean;
}

export function initializeCustomerFormData(countryCode: CountryCode, data?: CustomerFormData): CustomerFormData {
  return {
    id: data?.id || uuid(),
    salutation: data?.salutation || undefined,
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    dateOfBirth: data?.dateOfBirth || '',
    email: data?.email || '',
    phoneNumber: data?.phoneNumber || '',
    billingAddressZipCode: data?.billingAddressZipCode || '',
    billingAddressCity: data?.billingAddressCity || '',
    billingAddressCountryCode: data?.billingAddressCountryCode || countryCode,
    billingAddressStreet: data?.billingAddressStreet || '',
    billingAddressStreetNumber: data?.billingAddressStreetNumber || '',
    billingAddressAddressAddition: data?.billingAddressAddressAddition || '',
    billingAddressCareOf: data?.billingAddressCareOf || '',
    skipDoubleCheck: data?.skipDoubleCheck || false,
    isValidAddress: data?.isValidAddress || false,
  };
}

export const createOfferCustomerBillingAddressFrom = (data: CustomerFormData): PostalAddress => {
  return {
    id: data.id,
    type: DeliveryAddressType.Postal,
    salutation: data.salutation!,
    firstName: data.firstName,
    lastName: data.lastName,
    careOf: data.billingAddressCareOf,
    zipCode: data.billingAddressZipCode,
    city: data.billingAddressCity,
    street: data.billingAddressStreet,
    streetNumber: data.billingAddressStreetNumber,
    countryCode: data.billingAddressCountryCode,
    addressAddition: data.billingAddressAddressAddition,
  };
};

export const formatISODateToDaysMonthYear = (date?: Date | null): string => {
  return date ? dayjs(date).format(DEFAULT_DISPLAY_DATE_FORMAT) : '';
};


export type FormatMessage = IntlFormatters['formatMessage']

export const getSalutationOptions = (formatMessage: FormatMessage) => [
  { label: formatMessage({ id: 'orderDisplay.customer.salutation.ms' }), value: Salutation.miss },
  { label: formatMessage({ id: 'orderDisplay.customer.salutation.mr' }),  value: Salutation.mister },
];

export const newCustomerValidationSchema = (formatMessage: FormatMessage, country: CountryCode) => z.object({
  [CustomerFormField.salutation]: salutationValidationSchema(formatMessage),
  [CustomerFormField.firstName]: firstnameValidationSchema(formatMessage),
  [CustomerFormField.lastName]: lastnameValidationSchema(formatMessage),
  [CustomerFormField.dateOfBirth]: birthDateValidationSchema(formatMessage),
  [CustomerFormField.phoneNumber]: phoneNumberValidationSchema(formatMessage).optional(),
  [CustomerFormField.email]: emailValidationSchema(formatMessage).optional(),
  [CustomerFormField.billingAddressStreetNumber]: streetNumberValidationSchema(formatMessage),
  [CustomerFormField.billingAddressZipCode]: zipCodeValidationSchema(formatMessage, country),
  [CustomerFormField.billingAddressStreet]: streetValidationSchema(formatMessage),
  [CustomerFormField.billingAddressCity]: cityValidationSchema(formatMessage),
  [CustomerFormField.billingAddressAddressAddition]: addressAdditionValidationSchema(formatMessage).optional(),
  [CustomerFormField.billingAddressCareOf]: careOfValidationSchema(formatMessage).optional()
});

const salutationValidationSchema = (formatMessage: FormatMessage) => (
  z.string({ required_error: formatMessage({ id: 'customer.salutation.required' }) }).trim()
);

const firstnameValidationSchema = (formatMessage: FormatMessage) =>(
  z.string({ required_error: formatMessage({ id: 'customer.firstName.validValue' }) })
    .regex(NOT_EMAIL_REGEX,  formatMessage({ id: 'customer.firstName.validValue' }))
    .min(2, formatMessage({ id: 'customer.firstName.minCaract' })).trim()
);

const lastnameValidationSchema = (formatMessage: FormatMessage) =>(
  z.string({ required_error: formatMessage({ id: 'customer.lastName.validValue' }) }).trim()
    .regex(NOT_EMAIL_REGEX,  formatMessage({ id: 'customer.lastName.validValue' }))
    .min(2, formatMessage({ id: 'customer.lastName.minCaract' })).trim()
);

const phoneNumberValidationSchema = (formatMessage: FormatMessage) => {
  return z.string({ required_error: formatMessage({ id: 'customer.phoneNumber.required' }) })
    .regex(PHONE_NUMBER_REGEX, formatMessage({ id: 'customer.phoneNumber.validValue' }));
};

const emailValidationSchema = (formatMessage: FormatMessage) =>(
  z.string().refine(value => value === '' || value.match(CRM_EMAIL_REGEX) !== null, formatMessage({ id: 'customer.email.validValue' }))
);

const birthDateValidationSchema = (formatMessage: FormatMessage) =>
  z.string({ required_error: formatMessage({ id: 'customer.dateOfBirth.required' }) })
    .regex(DATE_REGEX,  formatMessage({ id: 'customer.dateOfBirth.required' }))
    .refine(value => {
      const [day, month, year] = value.split('.').map(datePart => parseInt(datePart, 10));
      const birthDate = dayjs(new Date(year, month - 1, day));
      return birthDate < eighteenYearsAgo() && year >= 1900;
    }, formatMessage({ id: 'customer.dateOfBirth.required' }));

export const ZipCodeRegexByCountry: { [key: string]: RegExp } = {
  [CountryCode.de]: DE_ZIP_CODE_REGEX,
  [CountryCode.at]: AT_ZIP_CODE_REGEX,
};

const zipCodeValidationSchema = (formatMessage: FormatMessage, countryCode: CountryCode) => {
  return z.string({ required_error: formatMessage({ id: 'customer.zipCode.validValue' }) })
    .regex(NOT_EMAIL_REGEX, formatMessage({ id: 'customer.zipCode.validValue' }))
    .refine(value => ZipCodeRegexByCountry[countryCode].test(value), { message: formatMessage({ id: 'customer.zipCode.validValue' }) });
};

const containsInvalidChar = (value: string) => !INVALID_ADDRESS_CHARACTERS_REGEX.test(value);

const cityValidationSchema = (formatMessage: FormatMessage) => (
  z.string({ required_error: formatMessage({ id: 'customer.city.validValue' }) })
    .regex(NOT_EMAIL_REGEX, formatMessage({ id: 'customer.city.validValue' }))
    .refine(containsInvalidChar, formatMessage({ id: 'no invalid characters' }))
);

const streetValidationSchema = (formatMessage: FormatMessage) => (
  z.string({ required_error: formatMessage({ id: 'customer.street.required' }) })
    .regex(NOT_EMAIL_REGEX, formatMessage({ id: 'customer.street.validValue' }))
    .refine(containsInvalidChar, formatMessage({ id: 'no invalid characters' }))
    .refine((value) => value.replace(NOT_LETTERS_REGEX, '').length >= 3, formatMessage({ id: 'customer.street.required' }))
);


const streetNumberValidationSchema = (formatMessage: FormatMessage) => (
  z.string({ required_error: formatMessage({ id: 'customer.streetNumber.validValue' }) })
    .regex(NOT_EMAIL_REGEX, formatMessage({ id: 'customer.streetNumber.startWithNumber' }))
    .regex(SHOULD_START_WITH_NUMBER_REGEX, formatMessage({ id: 'customer.streetNumber.startWithNumber' }))
    .refine(containsInvalidChar, formatMessage({ id: 'no invalid characters' }))
);

const careOfValidationSchema = (formatMessage: FormatMessage) => (
  z.string()
    .regex(NOT_EMAIL_REGEX, formatMessage({ id: 'address.create.validation.required.careOf' }))
    .refine((value) => !POST_NUMBER_REGEX.test(value), formatMessage({ id: 'address.create.validation.required.careOf' }))
    .refine((value) => !PACKSTATION_STREET_REGEX.test(value), formatMessage({ id: 'address.create.validation.required.careOf' }))
);

const addressAdditionValidationSchema = (formatMessage: FormatMessage) => (
  z.string().trim()
    .regex(NOT_EMAIL_REGEX, formatMessage({ id: 'no invalid characters' }))
    .refine((value) => !POST_NUMBER_REGEX.test(value), formatMessage({ id: 'no invalid characters' }))
    .refine((value) => !PACKSTATION_STREET_REGEX.test(value), formatMessage({ id: 'no invalid characters' }))
);
