const ERROR_CACHE_KEY = 'errors_cache';

interface ErrorCache {
  [key: string]: number | undefined
}

export function getErrorsCache(): ErrorCache {
  try {
    const errors = sessionStorage.getItem(ERROR_CACHE_KEY);
    return errors ? JSON.parse(errors) ?? {} : {};

  } catch (error) {
    console.error('Couldn\'t get errors cache from localstorage', error);
    return {};
  }
}

export function setInErrorsCache(errorKey: string) {
  try {
    const errors = getErrorsCache();
    errors[errorKey] = new Date().getTime();
    sessionStorage.setItem(ERROR_CACHE_KEY, JSON.stringify(errors));
  } catch (error) {
    console.error('Couldn\'t set errors cache in localstorage', error);
  }
}

export function isErrorCached(errorKey: string): boolean {
  try {
    const errors = getErrorsCache();
    if (!errors) {
      return false;
    }
    const item = errors[errorKey];
    return item ? (new Date()) < (new Date(item + 10800000)) : false;
  } catch (error) {
    console.error('Couldn\'t check if error is cached in localstorage', error);
    return false;
  }
}
