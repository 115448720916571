import dayjs from 'dayjs';

import { RejectionReasonType } from 'src/api/orderTypes';
import { DeliveryAddress } from 'src/types/customer/address';
import { Dimension, Price } from 'src/types/offer/Basket';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { DeliveryTimeMessage } from 'src/types/product/DeliveryTime';
import {
  ProductBrand,
  ProductDetails,
  ProductName,
  VariantValue,
} from 'src/types/product/product';
import { SubscriptionProductCustomer } from 'src/types/subscription/SubscriptionProductCustomer';
import {
  KeywordType,
  SearchDateType,
  SubscriptionStatusRequest
} from 'src/types/subscription/SubscriptionsOverviewRequest';


export enum SubscriptionItemStatusType {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  DELAYED_IN_DELIVERY = 'DELAYED_IN_DELIVERY',
}

export enum SubscriptionCancellationReason {
  INCOMPATIBILITY = 'INCOMPATIBILITY',
  COLLECTION = 'COLLECTION',
  QUANTITY_BECAME_TOO_MUCH = 'QUANTITY_BECAME_TOO_MUCH',
  CUSTOMER_DIDNT_WANT_SUBSCRIPTION = 'CUSTOMER_DIDNT_WANT_SUBSCRIPTION',
  PRODUCT_REGULARLY_OFFERED_CHEAPER = 'PRODUCT_REGULARLY_OFFERED_CHEAPER',
  NO_MORE_NEED = 'NO_MORE_NEED',
  NO_INDICATION_OF_REASON = 'NO_INDICATION_OF_REASON',
  DOUBLE_SUBSCRIPTION = 'DOUBLE_SUBSCRIPTION',
  WRONG_SUBSCRIPTION = 'WRONG_SUBSCRIPTION',
  ORDER_RESTRICTION = 'ORDER_RESTRICTION',
  NO_LONGER_ON_OFFER = 'NO_LONGER_ON_OFFER',
  HSE_DECISION = 'HSE_DECISION',
  VARIANT_CHANGE = 'VARIANT_CHANGE',
  PRICE_INCREASE = 'PRICE_INCREASE',

  // DEPRECATED REASONS; USED ONLY FOR BACKWARD COMPATIBILITY
  ANIMAL_DIED = 'ANIMAL_DIED',
  SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
}

export type SubscriptionItemStatus = {
  type: SubscriptionItemStatusType,
  timestamp: string,
  agentUsername?: string,
  reason?: SubscriptionCancellationReason | SubscriptionItemFailure,
  orderId?: string;
  failure?: SubscriptionItemFailure,
}

export interface SubscriptionItemFailure {
  title: string;
  details: string;
  errorKey?: string;
  rejectionReason?: SubscriptionRejectionReason;
}

export enum SubscriptionRejectionErrorKey {
  DEVIANT_CUSTOMER_DATA = 'deviant_customer_data',
  GENERAL_CRM_REJECTION = 'general_crm_rejection',
  GENERAL_ERP_REJECTION = 'general_erp_rejection',
  Unknown = 'unknown'
}

export type SubscriptionRejectionReason =
  SubscriptionCustomerModificationRequired
  | SubscriptionCrmErrorCodeReason
  | SubscriptionErpRejectionReason
  | SubscriptionExceptionReason

export interface SubscriptionCustomerModificationRequired {
  type: RejectionReasonType.CustomerModificationRequired;
  fields?: string[][];
}

export interface SubscriptionCrmErrorCodeReason {
  type: RejectionReasonType.CrmErrorCode;
  errorCode?: string;
  errorMessage?: string;
}

export interface SubscriptionErpRejectionReason {
  type: RejectionReasonType.ErpRejectionReason;
  details?: SubscriptionErpRejectionDetails;
}

export interface SubscriptionErpRejectionDetails {
  code: string;
  message: string;
  transaction_id: string;
}

export interface SubscriptionExceptionReason {
  type: RejectionReasonType.Exception;
}

export const createSubscriptionStatus = (
  type: SubscriptionItemStatusType,
  agentName: string,
  reason?: SubscriptionCancellationReason,
): SubscriptionItemStatus => ({
  type,
  reason,
  timestamp: dayjs().toISOString(),
  agentUsername: agentName,
});

export interface SubscriptionItemProduct {
  sku: string;
  quantity: number;
  thumbnail?: string;
  totalAmount?: number;
  productDetails?: ProductDetails;
  variant?: VariantValue;
}

export interface SubscriptionPayment {
  method: PaymentMethod;
  accountHolder?: string,
  iban?: string,
  usesPrefilledIban?: boolean
}
export interface SubscriptionItemDetails {
  customer: SubscriptionProductCustomer;
  product: SubscriptionItemProduct;
  payment: SubscriptionPayment;
  deliveryAddress: DeliveryAddress;
  salesChannel: string;
  salesOffice: string;
  sourceChannel: string;
  shippingCost: number;
}

export type SubscriptionInfo = {
  rotation: string;
  startIn: string;
  aboNumber: string;
  nextOrderDate: string;
}

export type SubscriptionItem = {
  id: string;
  contractNumber: number;
  externalCustomerId: string;
  status: SubscriptionItemStatus;
  details: SubscriptionItemDetails;
  aboInfo: SubscriptionInfo;
  previousOrder?: SubscriptionProductOrderResult;
  product: SubscriptionOfferProduct;
};

export type SubscriptionHistoryResponse = {
  subscriptions: SubscriptionItem[];
};

export type SubscriptionProductOrderResult = {
  result: SubscriptionProductOrderResultStatus,
  details?: SubscriptionProductOrderResult,
}

export type SubscriptionProductOrderResultStatus = {
  type: SubscriptionProductOrderResultStatusType,
  orderId?: string,
  timestamp: string,
}

export enum SubscriptionProductOrderResultStatusType {
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED'
}

export interface CancellationSubscriptionItemRequest {
  reason: SubscriptionCancellationReason,
  subscriptions: string[],
  selectAll?: boolean,
  filters?: SubscriptionCancellationFilters
}

export interface SubscriptionCancellationFilters {
  keywords?: string;
  status: SubscriptionStatusRequest;
  startDate?: Date;
  endDate?: Date;
  searchDateType?: SearchDateType;
  keywordType?: KeywordType;
  excludeSubscriptionIds?: string[],
  subscriptionsTotalToCancel: number,
}

export interface SubscriptionOfferItemVariant {
  dimensions: Dimension[];
  price: Price;
  sku: string;
  imageSrc: string;
  deliveryTime?: DeliveryTimeMessage;
}

export interface SubscriptionOfferProduct {
  baseProductNo: string;
  name: ProductName;
  brand: ProductBrand;
  description: string;
  variant: SubscriptionOfferItemVariant;
  baseImageUri: string;
}
