import deTranslationMessages from './de';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatTranslationMessages = (locale: string, messages: any): {} => {
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = {
  de: deTranslationMessages,
};

export function formatMessage(messageId: string, args?: {[field: string]: string}) {
  const deMessage = deTranslationMessages[messageId].toString();
  return !args ? deMessage : Object.keys(args).reduce(
    (previousValue, field: string) => previousValue.replace(`{${field}}`, args[field]),
    deMessage,
  );
}
