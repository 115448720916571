import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { CancellationWaitlistItemRequest, WaitlistItemCancellationReason } from 'src/types/waitlist/CreateWaitlistItemRequest';
import {
  WaitlistHistoryItem,
  WaitlistHistoryItemsResponse,
  WaitlistItemCancelled,
  WaitlistItemStatusType
} from 'src/types/waitlist/WaitlistHistoryResponse';


export interface WaitlistHistoryFilters {
  keywords?: string;
  searchFrom?: Date | null;
  searchTo?: Date | null;
}

export interface WaitlistHistoryState {
  loading: boolean;
  data: WaitlistHistoryItem[];
  waitlistItemId?: string;
  waitlistItemCancellationReason?: WaitlistItemCancellationReason;
  error?: boolean;
  errorCode?: number;
  externalCustomerId?: string;
  filters: WaitlistHistoryFilters;
  infiniteScrollState: InfiniteScrollState;
}

export interface InfiniteScrollState {
  offset: number;
  isLoadingMore: boolean;
  hasMore: boolean;
}

export const waitlistHistoryInitialState: WaitlistHistoryState = {
  loading: false,
  filters: {
    keywords: undefined,
    searchFrom: undefined,
    searchTo: undefined,
  },
  data: [],
  infiniteScrollState: {
    offset: 1,
    isLoadingMore: false,
    hasMore: false,
  }
};

export const fetchWaitlistHistory = createAction<{ externalCustomerId?: string, size?: number }>
('waitlistHistory/FETCH_WAITLIST_HISTORY');
export type FetchWaitlistHistory = ReturnType<typeof fetchWaitlistHistory>;

export const cancelWaitlistItem = createAction<{
  customerId: string,
  cancellationWaitlistItemRequest: CancellationWaitlistItemRequest
}>('waitlistHistory/CANCEL_WAITLIST_ITEM');
export type CancelWaitlistItem = ReturnType<typeof cancelWaitlistItem>;

const waitlistHistorySlice = createSlice({
  name: 'waitlistHistory',
  initialState: waitlistHistoryInitialState,
  reducers: {
    fetchWaitlistHistorySuccess(state, { payload }: PayloadAction<WaitlistHistoryItemsResponse>) {
      state.data = payload.items;
      state.loading = false;
      state.infiniteScrollState.isLoadingMore = false;
      state.infiniteScrollState.offset++;
      state.infiniteScrollState.hasMore = state.data.length < payload.total;
    },
    fetchWaitlistHistoryError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
      state.infiniteScrollState.isLoadingMore = false;
    },
    setWaitlistHistoryFilters(state, { payload }: PayloadAction<WaitlistHistoryFilters>) {
      state.filters = {
        keywords: payload.keywords === null ? undefined : payload.keywords ?? state.filters.keywords,
        searchFrom: payload.searchFrom === null ? undefined : payload.searchFrom ?? state.filters.searchFrom,
        searchTo: payload.searchTo === null ? undefined : payload.searchTo ?? state.filters.searchTo,
      };
      state.data = [];
      state.infiniteScrollState.offset = 1;
      state.infiniteScrollState.hasMore = false;
      state.loading = true;
    },
    clearWaitlistHistoryFilters(state) {
      state.filters = waitlistHistoryInitialState.filters;
      state.loading = true;
      state.data = [];
      state.infiniteScrollState.offset = 1;
    },
    clearWaitlistHistory() {
      return waitlistHistoryInitialState;
    },
    cancelWaitlistItemInProgress(state) {
      state.loading = true;
    },
    cancelWaitlistItemSuccess(state, { payload }: PayloadAction<CancellationWaitlistItemRequest>) {
      const { waitlistItemId, cancellationReason } = payload;
      const item = state.data.find(it => it.id === waitlistItemId);
      if (item) {
        item.status = {
          ...item.status,
          reason: cancellationReason.reason,
          type: WaitlistItemStatusType.CANCELLED
        } as unknown as WaitlistItemCancelled;
      }
      state.loading = false;
    },
    cancelWaitlistItemError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    setReasonOfCancellation(state, { payload }: PayloadAction<WaitlistItemCancellationReason | undefined>) {
      state.waitlistItemCancellationReason = payload;
    },
    setWaitlistCancellationItemId(state, { payload }: PayloadAction<string | undefined>) {
      state.waitlistItemId = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWaitlistHistory, (state, { payload }: PayloadAction<{ externalCustomerId?: string, size?: number }>) => {
        state.externalCustomerId = payload.externalCustomerId;
        state.loading = state.infiniteScrollState.offset === 1;
        state.infiniteScrollState.isLoadingMore = state.infiniteScrollState.offset !== 1;
      });
  }
});

export const {
  fetchWaitlistHistorySuccess,
  fetchWaitlistHistoryError,
  setWaitlistHistoryFilters,
  clearWaitlistHistoryFilters,
  clearWaitlistHistory,
  cancelWaitlistItemInProgress,
  cancelWaitlistItemSuccess,
  cancelWaitlistItemError,
  setReasonOfCancellation,
  setWaitlistCancellationItemId,
} = waitlistHistorySlice.actions;

export default waitlistHistorySlice.reducer;
