import { FormatError } from 'intl-messageformat';
import React from 'react';
import {
  IntlProvider,
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  UnsupportedFormatterError
} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { translationMessages } from 'src/i18n';
import { logErrorEvent } from 'src/logging/loggingActions';
import { LanguageOptions } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';


interface Props {
  children: React.ReactElement;
}

const LanguageProvider: React.FC<Props> = (props: Props) => {
  const locale = useSelector(appSelectors.getLanguage);
  const dispatch = useDispatch();

  const logError = (error: MissingTranslationError | MessageFormatError | MissingDataError | InvalidConfigError | UnsupportedFormatterError | FormatError) => {
    dispatch(
      logErrorEvent({
        message: `problem while handling translation, error: ${JSON.stringify(error)}`,
        err: error,
        args: error,
      }),
    );
  };

  return (
    <IntlProvider
      locale="de"
      key={locale}
      messages={translationMessages[LanguageOptions.de]}
      {...props}
      onError={logError}
    >
      {React.Children.only(props.children) || null}
    </IntlProvider>
  );
};

export default LanguageProvider;
