export default {
  'app.title': 'Digitale Plattform - Auftrags-Backoffice',
  'app.headerSearch': 'Produkt, Marke, Kategorie, Bestellnr',
  'app.headerSearchButton': 'Suchen',
  'app.salesDrivers': 'Angebot',
  'app.freeshipping': 'Versand Gratis',
  'app.search': 'suchen',
  'app.advancedSearch': 'Erweiterte Suche',
  'app.email': 'E-Mail',
  'app.salutation.mr': 'Herr',
  'app.salutation.ms': 'Frau',
  'app.firstName': 'Vorname',
  'app.lastName': 'Nachname',
  'app.dateOfBirth': 'Geburtsdatum',
  'app.phone': 'Telefon',
  'app.street': 'Straße',
  'app.streetNumber': 'Hausnummer',
  'app.zipcode': 'Postleitzahl',
  'app.postalCode': 'Postleitzahl',
  'app.city': 'Ort',
  'app.login': 'Einloggen',
  'app.errors.required': 'Erforderlich',
  'app.submit': 'sichern',
  'app.abort': 'abbrechen',
  'app.reset': 'zurücksetzen',
  'app.country': 'Land',
  'app.language': 'Sprache',
  'app.newSession': 'Neue Sitzung',
  'app.newSessionwithCustomer': 'Weiter einkaufen',
  'app.close': 'schließen',
  'app.logout': 'Abmelden',
  'app.unauthorized': 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen',
  'app.tablePagination': 'Zeilen pro Seite',
  'app.agentSalutation': 'Willkommen, {fullName}!',
  'app.chooseApp': 'Bitte wähle eine Applikation.',
  'app.orderApp.headline': 'WILIS - Wir lieben Shopping!',
  'app.orderApp.cta': 'Bestellungen erfassen',
  'app.wilisForCH.headline': 'WILIS - Wir lieben Shopping!',
  'app.wilisForCH.cta': 'Bestellungen erfassen',
  'app.rejectionMgmt.headline': 'Abwürfe',
  'app.rejectionMgmt.cta': 'Abwürfe bearbeiten',
  'app.voucherMgmt.headline': 'Gutscheinverwaltung',
  'app.voucherMgmt.cta': 'Gutscheine erstellen',
  'app.SolvencyCheck.headline': 'Kundenauskunft Bonität',
  'app.SolvencyCheck.cta': 'Bonität prüfen',
  'app.name': 'Name',
  'app.loginTime': 'Login-Zeit',
  'app.subscriptionProductManagement.headline': 'Treue Abo',
  'app.subscriptionProductManagement.cta': 'Abonnementverwaltung',
  'app.networkError': 'Es liegt ein Netzwerkfehler vor',
};
