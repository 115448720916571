export default {
  'address.create.validation.phoneNumber': 'Bitte telefonnummer prüfen',
  'address.create.validation.required.street': 'Bitte Straße prüfen',
  'address.create.validation.required.streetNumber': 'Bitte Hausnummer prüfen',
  'address.create.validation.required.zipCode': 'Bitte PLZ prüfen',
  'address.create.validation.required.city': 'Bitte Ort prüfen',
  'address.create.validation.required.firstName': 'Bitte Vorname prüfen',
  'address.create.validation.required.lastName': 'Bitte Nachname prüfen',
  'address.create.validation.required.PACKSTATION': 'Bitte die Packstation Nummer prüfen',
  'address.create.validation.required.POSTFILIALE': 'Bitte die Postfiliale Nummer prüfen',
  'address.create.validation.required.PAKETSHOP': 'Bitte die Paketshop Nummer prüfen',
  'address.create.validation.required.POSTSTATION': 'Bitte die Poststation Nummer prüfen',
  'address.create.validation.required.packstation': 'Bitte die Packstation Nummer prüfen',
  'address.create.validation.required.postNumber': 'Bitte DHL Postnummer prüfen',
  'address.create.validation.required.postNumber.warning': 'Bitte geben Sie Postnummer und Namen genauso an, wie in ihrem DHL-Account. Andernfalls wird die Lieferung möglicherweise nicht zugestellt',
  'address.create.validation.required.careOf': 'Ungültiger Adresszusatz',
  'address.create.validation.required.addressAddition': 'Ungültiger Hausnummernzusatz',
  'address.create.DHL.PACKSTATION': 'Packstation',
  'address.create.DHL.POSTFILIALE': 'Postfiliale',
  'address.create.DHL.PAKETSHOP': 'Paketshop',
  'address.create.DHL.POSTSTATION': 'Poststation',
  'address.create.DHL.serviceNumberLabel.PACKSTATION': 'Nummer der Packstation',
  'address.create.DHL.serviceNumberLabel.POSTFILIALE': 'Nummer der Postfiliale',
  'address.create.DHL.serviceNumberLabel.PAKETSHOP': 'Nummer der Paketshop',
  'address.create.DHL.serviceNumberLabel.POSTSTATION': 'Nummer der Poststation',
  'address.new': 'Neue Adresse',
  'address.billingAsDelivery': 'Liefern an die Rechnungsadresse',
  'address.deliveryAddress': 'Abweichende Lieferadresse {index}',
  'address.packstation': 'Packstation',
  'address.postal': 'Post',
  'deliveryAddress.billingAddress':'Lieferadresse (Rechnungsadresse): ',
  'deliveryAddress.postalAddress':'Lieferadresse (Postanschrift): ',
  'deliveryAddress.packstationAddress':'Lieferadresse (Packstation): ',
  'deliveryAddress.dhlAddress':'Lieferadresse (DHL-Optionen): ',
  'customer.newDelivery.address': 'Neue Lieferadressen hinzufügen',
  'customer.deliveryAddress.chooseAsPreferred': 'Als bevorzugte Lieferadresse festlegen',
  'customer.deliveryAddress.preferred': 'Bevorzugte Lieferadresse',

  'customer.deliveryAddress.maxAllowedAddressesReached': 'Max. Anzahl Lieferadressen erreicht',
  'address.cantUsePackstation': 'Sperrige Artikel werden nicht an eine Packstation geliefert',
  'address.cantUsePackstationSelectAnotherAddress': 'Sperrige Artikel werden nicht an eine Packstation geliefert. Bitte andere Lieferadresse auswählen',
  'address.cantDeliverToThisAddressSelectAnotherAddress': 'Lieferung an diese Adresse nicht möglich, Bitte wählen Sie ein anderes aus.',
  'address.packstationPostNumberInvalid': 'Packstation fehlerhaft. Bitte neu erfassen.',

  'address.kat1.title': 'Bestellung nicht möglich',
  'address.kat1.title.delivery': 'Bestellung an diese Lieferadresse nicht möglich',
  'address.kat1.cantUseAddressDE': '“Eine Bestellung an diese Adresse ist nicht möglich. Bitte wenden Sie sich an die Service-Kollegen, unter der Rufnummer 0800 - 23 414 74.”',
  'address.kat1.cantUseAddressAT': '“Eine Bestellung an diese Adresse ist nicht möglich. Bitte wenden Sie sich an die Service-Kollegen, unter der Rufnummer 0800 - 23 41 47.”',
  'address.kat1.reason.invoice': '“Das sehe ich hier nicht, aber die Kollegen in der Service-Hotline helfen Ihnen gerne weiter.”',
  'address.kat1.cantUseDeliveryAddress': '“Eine Bestellung an diese Lieferadresse ist nicht möglich. Bitte Rechnungsadresse verwenden.”',
  'address.kat1.reason.deliveryDE': '“Das sehe ich hier nicht. Bitte wenden Sie sich bei Fragen an die Kollegen der Service-Hotline, unter der Rufnummer 0800 - 23 414 74. Diese sind von 8 bis 21 Uhr erreichbar.”',
  'address.kat1.reason.deliveryAT': '“Das sehe ich hier nicht. Bitte wenden Sie sich bei Fragen an die Kollegen der Service-Hotline, unter der Rufnummer 0800 - 23 41 47. Diese sind von 8 bis 21 Uhr erreichbar.”',
  'address.kat1.reason.deliveryCH': '“Das sehe ich hier nicht. Bitte wenden Sie sich bei Fragen an die Kollegen der Service-Hotline, unter der Rufnummer 0800 - 23 41 47. Diese sind von 8 bis 21 Uhr erreichbar.”',
  'address.kat1.useInvoiceAddress': 'Mit Rechnungsadresse fortfahren',
  'address.kat1.editOrderHint': 'Lieferung an diese Adresse nicht möglich.',
  'address.checkService.isDown': 'Adressprüfung vorübergehend offline.',
  'address.processWhenCheckService.isDown': 'Ohne Prüfung fortfahren.',

  'address.suggest.modalTitle': 'Bestätige die Adresse',
  'address.suggest.selectAddressText': 'Bitte die gewünschte Adresse auswählen',
  'address.suggest.confirmAddressText': 'Ist das die gewünschte Adresse?',
  'address.suggest.useSuggestion': 'Vorschlag verwenden',
  'address.suggest.keepEnteredAddress': 'Eingegebene Adresse behalten',
  'address.suggest.continue': 'Weiter',
};
