import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
} from 'redux-saga/effects';

import { ApiProduct } from 'src/api/apiProduct';
import { ApiUpsells } from 'src/api/apiUpsells';
import { logErrorEvent } from 'src/logging/loggingActions';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import {
  AddOrderItemAction,
  addOrderItemBySku,
  getBasket,
} from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { setSource, trackProductImpressionsEvent } from 'src/redux/product/productDetailsSlice';
import { fetchProductStock } from 'src/redux/product/productStockSlice';
import {
  clearUpsells,
  FetchUpsellsAction,
  updateUpsells,
  updateUpsellsMainProduct,
  updateUpsellsMainProductSku,
  setUpsellsLocation,
} from 'src/redux/product/productUpsellsSlice';
import getProductBySku from 'src/sagas/product/productService';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';
import { ItemSource } from 'src/types/offer/ItemSource';
import { ListType } from 'src/types/offer/ListType';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';
import {
  CrossSellFeature,
  CrossSellInfo,
  CrossSellResponse,
  CrossSellTrigger,
  DISPLAYED_CROSS_SELL_PRODUCTS_MAX,
} from 'src/types/product/CrossSell';
import { ProductDetails, ProductStatus } from 'src/types/product/product';
import { getCampaignChannelFromSalesChannel } from 'src/utils/formatters/freeShipping';
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';
import { getProductImpressionsView } from 'src/utils/getProductImpressionView';


const { getOpenedModals } = appSelectors;

function* getUpsellsProducts(
  apiProduct: ApiProduct,
  crossSells: CrossSellInfo[],
  sku: string,
  triggerType: CrossSellTrigger,
  mainProductName?: string,
) {
  if (crossSells.length) {
    const salesChannel = yield select(salesChannelSelector.getSalesChannel);
    const campaignSalesChannel = getCampaignChannelFromSalesChannel(salesChannel);
    const upsellsProductDetails = yield call(
      apiProduct.getProducts,
      crossSells.map(i => i.productNo),
      campaignSalesChannel
    );
    const upsellsProducts: ProductDetails[] = upsellsProductDetails.map(
      (product: ProductDetails) => ({
        ...product,
        itemSource: ItemSource.CROSS_SELL,
        crossSell: {
          ...crossSells.find(i => i.productNo === product.baseProductNo),
          leadProductSku: sku,
          triggerType: triggerType,
          leadProductName: mainProductName ?? '',
        },
      }),
    );
    const filteredUpsellsProducts = upsellsProducts
      .filter(i => i.status === ProductStatus.Sellable)
      .slice(0, DISPLAYED_CROSS_SELL_PRODUCTS_MAX);
    return {
      filteredUpsellsProducts,
      filteredUpsellsProductsBaseNo: filteredUpsellsProducts.map(
        (item: ProductDetails) => item.baseProductNo,
      ),
    };
  }

  return {
    filteredUpsellsProducts: [],
    filteredUpsellsProductsBaseNo: [],
  };
}

export function* fetchUpsellsSaga({
  payload,
  type,
}: AddOrderItemAction | FetchUpsellsAction | PayloadAction<EditOrderActionRequestWithOrderId>) {
  const isReklaOrder = yield select(orderEntrySelector.isReklaOrder);
  if (isReklaOrder) {
    return ;
  }
  yield put(clearUpsells());
  const triggerType =
    type === addOrderItemBySku.type ? CrossSellTrigger.AUTO : CrossSellTrigger.MANUAL;
  const skipUpsells = 'skipUpsells' in payload ? payload.skipUpsells : false;
  const sku =
    'sku' in payload
      ? payload.sku
      : 'sku' in payload.actionRequest
        ? payload.actionRequest.sku
        : '';
  let name =
    'name' in payload
      ? payload.name
      : 'productDetails' in payload
        ? payload.productDetails?.name.long
        : undefined;
  const openedModals = yield select(getOpenedModals);

  try {
    if (skipUpsells) {
      return;
    }

    let mainProduct: ProductDetails | undefined;
    if (!name) {
      mainProduct = yield call(getProductBySku, sku);
      name = mainProduct?.name?.long;
    }

    const apiUpsells: ApiUpsells = yield getContext(SagaContextItem.apiUpsells);
    const apiProduct: ApiProduct = yield getContext(SagaContextItem.apiProduct);
    const crossSellsResponse: CrossSellResponse = yield call(
      apiUpsells.getUpsells,
      toBaseProductNumber(sku),
    );

    const { crossSells, periodicalCrossSells } = crossSellsResponse;
    const salesChannel: string = yield select(orderEntrySelector.getOrderSalesChannel);

    if (crossSells.length || periodicalCrossSells.length) {
      const upsellsProducts = yield* getUpsellsProducts(apiProduct, crossSells, sku, triggerType, name);
      const periodicalUpsellsProducts = yield* getUpsellsProducts(apiProduct, periodicalCrossSells, sku, triggerType, name);

      let crossSellProducts = [
        ...upsellsProducts.filteredUpsellsProducts,
        ...periodicalUpsellsProducts.filteredUpsellsProducts,
      ];

      const upgradeProducts = crossSellProducts.filter(
        c => c.crossSell?.feature === CrossSellFeature.UPGRADE,
      );

      crossSellProducts = crossSellProducts.filter(
        c => c.crossSell?.feature !== CrossSellFeature.UPGRADE,
      );
      crossSellProducts = [...upgradeProducts, ...crossSellProducts];

      if (upsellsProducts.filteredUpsellsProductsBaseNo.length > 0 ||
          periodicalUpsellsProducts.filteredUpsellsProductsBaseNo.length > 0) {
        yield put(
          fetchProductStock([
            ...upsellsProducts.filteredUpsellsProductsBaseNo,
            ...periodicalUpsellsProducts.filteredUpsellsProductsBaseNo,
          ]),
        );
      }
      yield put(updateUpsells(crossSellProducts));

      if (upgradeProducts.length > 0) {
        const upgradeItems = JSON.parse(localStorage.getItem('upgradeProducts') || '[]');
        upgradeProducts.map((upgradeProduct) => {
          upgradeItems.push({
            sku: toBaseProductNumber(sku),
            upgradeSku: upgradeProduct.baseProductNo,
            crossSell: upgradeProduct.crossSell,
          });
        });
        localStorage.setItem('upgradeProducts', JSON.stringify(upgradeItems));
        yield put(getBasket());
      }

      if (name) {
        yield put(updateUpsellsMainProduct(name));
        yield put(updateUpsellsMainProductSku(sku));
        yield put(
          setSource({
            listType: ListType.CROSS_SELL,
            listName: `${toBaseProductNumber(sku)} | ${name}`,
          }),
        );
        yield put(
          trackProductImpressionsEvent(
            getProductImpressionsView({
              mainTvChannelOffer: [
                { offers: upsellsProducts.filteredUpsellsProducts, tab: sku, title: 'upsell' },
              ],
              salesChannel,
              upsellSku: sku,
              upsellsMainProduct: name,
            }),
          ),
        );
      }
    }

    const upsellsLocation =
      openedModals.includes(Modals.productDetails) || openedModals.includes(Modals.productSearch)
        ? openedModals.includes(Modals.productDetails)
          ? 'productdetails'
          : 'productsearch'
        : 'ordertable';

    yield put(setUpsellsLocation(upsellsLocation));
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not fetch cross-sells', err }));
  }
}
