import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest
} from 'redux-saga/effects';

import {
  addressFields,
  CustomerFormData,
  ZipCodeRegexByCountry
} from 'src/components/CustomerView/CustomerBlock/NewCustomer/utils';
import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal } from 'src/redux/app/appSlice';
import {
  createCustomerSuccess,
  setSelectedCustomerLocalId,
  setSelectedDeliveryAddress,
} from 'src/redux/customer/customerInfoSlice';
import fetchDuplicatedCustomers from 'src/sagas/customer/fetchDuplicatedCustomers';
import {
  closeNewCustomerModal,
  createNewCustomer,
  findAndSetCityByZip,
  loadingDone,
  newCustomerSelectors,
  setAddressCheckServiceDown,
  setCustomerData,
  setSuggestedAddress,
  setSuggestedCity,
  setSuggestedCustomers,
  setSuggestedCustomersByEmail,
  setUnknownAddressFields,
  UnknownAddressFields,
} from 'src/sagas/newCustomer/newCustomerSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { DeliveryAddressType, PostalDeliveryAddress } from 'src/types/customer/address';
import {
  AddressValidationResponse,
  badCityFlag,
  badHousenoFlag,
  badStreetFlag,
  badZipCodeFlag,
  isPartialZipCode
} from 'src/types/customer/AddressValidationResponse';
import { Customer } from 'src/types/customer/customer';
import { Modals } from 'src/types/Modals';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import { buildAddressValidationRequest } from 'src/utils/mappers/serializeAddress';


export function* createNewCustomerSaga(action: PayloadAction<CustomerFormData>) {
  try {
    const customerData = action.payload;
    yield put(setCustomerData(customerData));
    if (!customerData.isValidAddress) {
      const isValidAddress: boolean = yield call(validateAddress, customerData);
      if (!isValidAddress) {
        return;
      }
    }

    if (!customerData.skipDoubleCheck) {
      const suggestedCustomersByEmail: Customer[] = yield call(fetchDuplicatedCustomers, { email: customerData.email });
      if (suggestedCustomersByEmail.length > 0) {
        yield put(setSuggestedCustomersByEmail(suggestedCustomersByEmail[0]));
        return;
      }
      const suggestedCustomers: Customer[] = yield call(fetchDuplicatedCustomers, { ...customerData, email: undefined });
      if (suggestedCustomers.length > 0) {
        yield put(setSuggestedCustomers(suggestedCustomers));
        return;
      }
    }

    yield call(createNewCustomerSuccessSaga, customerData);
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not create new customer', err }));
  } finally {
    yield put(loadingDone());
  }
}

function* validateAddress(customerData: CustomerFormData) {
  try {
    const apiAddress = yield getContext(SagaContextItem.apiAddress);
    const addressCheckRequest = buildAddressCheckRequest(customerData);
    const suggestedAddresses: AddressValidationResponse = yield call(apiAddress.validateAddress, addressCheckRequest);
    const completeAddresses = suggestedAddresses.completeAddresses;
    const completeAddress = completeAddresses.length === 1 ? completeAddresses[0] : undefined;
    const givenAddressIsGood = completeAddress?.street === addressCheckRequest.street &&
      completeAddress.streetNumber === addressCheckRequest.streetNumber &&
      completeAddress.zipCode === addressCheckRequest.zipCode &&
      completeAddress.city === addressCheckRequest.city;

    if (givenAddressIsGood) {
      yield put(setCustomerData( { ...customerData, isValidAddress: true } ));
      return true;
    } else {
      if (completeAddresses.length > 0) {
        yield put(openModal((Modals.addressSuggestion)));
        yield put(setSuggestedAddress(completeAddresses));
        return false;
      }

      const partialCorrectAddress = suggestedAddresses.partialAddresses;

      const probableCorrectAddress = partialCorrectAddress?.find(a => isPartialZipCode(a.zipCodeFlag));
      const partialAddress = probableCorrectAddress || partialCorrectAddress?.[0];
      const unknownAddressFields: UnknownAddressFields = partialAddress && {
        billingAddressStreet: badStreetFlag(partialAddress?.streetFlag) ? partialAddress : undefined,
        billingAddressStreetNumber: badHousenoFlag(partialAddress?.housenoFlag) ? partialAddress : undefined,
        billingAddressZipCode: probableCorrectAddress || badZipCodeFlag(partialAddress?.zipCodeFlag) ? partialAddress : undefined,
        billingAddressCity: badCityFlag(partialAddress?.cityFlag) ? partialAddress : undefined,
      };
      yield put(setUnknownAddressFields(unknownAddressFields || defaultUnknownAddressFields));
      return false;
    }
  } catch (err) {
    yield put(setAddressCheckServiceDown());
    return false;
  }
}

function* findAndSetCityByZipSaga(action: PayloadAction<CustomerFormData>) {
  try {
    const payload = action.payload;
    const previousData: CustomerFormData = yield select(newCustomerSelectors.getCurrentCustomerData);
    const previousSuggestedCity: string | undefined = yield select(newCustomerSelectors.getSuggestedCity);
    const isValidZipCode = ZipCodeRegexByCountry[payload.billingAddressCountryCode].test(payload.billingAddressZipCode);
    if (isValidZipCode) {
      yield put(setCustomerData(payload));
    }
    const sameAddress = isSameBillingAddressData(previousData, payload);
    if (
      !(payload.billingAddressZipCode && payload.billingAddressStreet && payload.billingAddressStreetNumber) ||
      (sameAddress && previousSuggestedCity === payload.billingAddressCity) ||
      !isValidZipCode
    ) {
      return;
    }
    yield put(setSuggestedCity(''));

    const apiAddress = yield getContext(SagaContextItem.apiAddress);
    const suggestedAddresses: AddressValidationResponse = yield call(apiAddress.validateAddress, buildAddressCheckRequest(action.payload));
    yield put(setSuggestedCity(suggestedAddresses.completeAddresses.length === 1 ? suggestedAddresses.completeAddresses[0].city : payload.billingAddressCity));
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could findAndSetCityByZipSaga', err }));
  } finally {
    yield put(loadingDone());
  }
}

function buildAddressCheckRequest(data: CustomerFormData) {
  const { billingAddressZipCode, billingAddressStreet, billingAddressStreetNumber, billingAddressCountryCode, billingAddressCity } = data;
  return buildAddressValidationRequest(billingAddressCountryCode, billingAddressStreet, billingAddressStreetNumber, billingAddressZipCode, billingAddressCity);
}
function isSameBillingAddressData(data: CustomerFormData, currentData?: CustomerFormData): boolean {
  return !!currentData && addressFields.map(f => data[f]).join('') === addressFields.map(f => currentData[f]).join('');
}

export function* createNewCustomerSuccessSaga(data: CustomerFormData) {
  const invoiceAddress: PostalDeliveryAddress = {
    id: data.id,
    externalAddressId: '',
    type: DeliveryAddressType.Postal,
    firstName: data.firstName,
    lastName: data.lastName,
    salutation: data.salutation,
    addressAddition: data.billingAddressAddressAddition,
    city: data.billingAddressCity,
    countryCode: data.billingAddressCountryCode,
    careOf: data.billingAddressCareOf,
    street: data.billingAddressStreet,
    streetNumber: data.billingAddressStreetNumber,
    zipCode: data.billingAddressZipCode,
    fromInvoiceAddress: true,
  };
  const customer: Customer = {
    id: data.id,
    deliveryAddresses: [invoiceAddress],
    firstName: data.firstName,
    lastName: data.lastName,
    salutation: data.salutation,
    dateOfBirth: data.dateOfBirth,
    phoneNumbers: [],
    origin: 'DPL',
    externalCustomerId: '',
    isCreateDeliveryAddressAllowed: true,
    billingAddress: {
      id: invoiceAddress.id,
      careOf: invoiceAddress.careOf,
      street: invoiceAddress.street,
      streetNumber: invoiceAddress.streetNumber,
      addressAddition: invoiceAddress.addressAddition,
      zipCode: invoiceAddress.zipCode,
      city: invoiceAddress.city,
      countryCode: invoiceAddress.countryCode,
    },
    skipDoubleCheck: data.skipDoubleCheck,
  };

  const localCustomerId = localCustomerStorage.saveCustomer(customer);
  yield put(setSelectedCustomerLocalId(localCustomerId));
  yield put(createCustomerSuccess(customer));
  yield put(setSelectedDeliveryAddress(invoiceAddress));
  yield put(closeNewCustomerModal());
  yield put(openModal(Modals.consentManagement));
}

const defaultUnknownAddressFields: UnknownAddressFields = {
  billingAddressStreet: { city: '', street: '', zipCode: '', streetNumber: '' },
  billingAddressStreetNumber: { city: '', street: '', zipCode: '', streetNumber: '' },
  billingAddressZipCode: { city: '', street: '', zipCode: '', streetNumber: '' },
  billingAddressCity: { city: '', street: '', zipCode: '', streetNumber: '' },
};

export default function* createNewCustomerWatcher() {
  yield takeLatest(createNewCustomer.type, createNewCustomerSaga);
  yield takeLatest(findAndSetCityByZip.type, findAndSetCityByZipSaga);
}
