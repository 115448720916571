import {
  createAction,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import {
  CustomerFormData,
  CustomerFormField,
  initializeCustomerFormData
} from 'src/components/CustomerView/CustomerBlock/NewCustomer/utils';
import { RootStateType } from 'src/constants/types';
import { AddressValidationResponseItem } from 'src/types/customer/AddressValidationResponse';
import { Customer } from 'src/types/customer/customer';
import countryLocalStorage from 'src/utils/countryLocalStorage';
import { createSelectors } from 'src/utils/state';


export interface NewCustomerState {
  loading: boolean;
  formData: CustomerFormData;
  suggestedAddresses?: AddressValidationResponseItem[];
  suggestedCustomers: Customer[];
  duplicatedEmailCustomer?: Customer;
  isAddressCheckServiceDown?: boolean;
  isModalOpen: boolean;
  suggestedCity?: string;
  unknownAddressFields: UnknownAddressFields;
}

export type UnknownAddressFields = {
  [CustomerFormField.billingAddressStreet]?: AddressValidationResponseItem;
  [CustomerFormField.billingAddressStreetNumber]?: AddressValidationResponseItem;
  [CustomerFormField.billingAddressZipCode]?: AddressValidationResponseItem;
  [CustomerFormField.billingAddressCity]?: AddressValidationResponseItem;
  [fieldName: string]: AddressValidationResponseItem | undefined;
};

export const newCustomerStateInitialState: NewCustomerState = {
  loading: false,
  suggestedCustomers: [],
  isModalOpen: false,
  formData: initializeCustomerFormData(countryLocalStorage.get()),
  unknownAddressFields: {}
};


export const createNewCustomer = createAction<CustomerFormData>('newCustomer/create');
export const findAndSetCityByZip = createAction<CustomerFormData>('newCustomer/findAndSetCityByZip');

const newCustomerSlice = createSlice({
  name: 'newCustomer',
  initialState: newCustomerStateInitialState,
  reducers: {
    setCustomerData(state, payload: PayloadAction<CustomerFormData>) {
      state.formData = payload.payload;
    },
    setAddressCheckServiceDown(state) {
      state.isAddressCheckServiceDown = true;
    },
    openNewCustomerModal(state, action: PayloadAction<CustomerFormData>) {
      state.isModalOpen = true;
      state.formData = action.payload;
    },
    closeNewCustomerModal(state) {
      state.isModalOpen = false;
      state.suggestedAddresses = undefined;
      state.suggestedCustomers = newCustomerStateInitialState.suggestedCustomers;
      state.formData = newCustomerStateInitialState.formData;
      state.isAddressCheckServiceDown = undefined;
      state.duplicatedEmailCustomer = undefined;
      state.suggestedCity = undefined;
    },
    setSuggestedAddress(state, action: PayloadAction<AddressValidationResponseItem[]>) {
      state.suggestedAddresses = action.payload;
    },
    setSuggestedCustomers(state, action: PayloadAction<Customer[]>) {
      state.suggestedCustomers = action.payload;
    },
    setSuggestedCustomersByEmail(state, action: PayloadAction<Customer | undefined>) {
      state.duplicatedEmailCustomer = action.payload;
    },
    setSuggestedCity(state, action: PayloadAction<string>) {
      state.suggestedCity = action.payload;
      state.formData.billingAddressCity = action.payload;
    },
    loadingDone(state) {
      state.loading = false;
    },
    setUnknownAddressFields(state, action: PayloadAction<UnknownAddressFields | undefined>) {
      state.unknownAddressFields = action.payload || {};
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createNewCustomer, state => {
        state.loading = true;
        state.suggestedCustomers = [];
        state.suggestedAddresses = undefined;
        state.unknownAddressFields = {};
        state.isAddressCheckServiceDown = false;
      })
      .addCase(findAndSetCityByZip, state => { state.loading = true; });
  },
});

export const {
  setCustomerData,
  closeNewCustomerModal,
  openNewCustomerModal,
  setSuggestedAddress,
  setAddressCheckServiceDown,
  setSuggestedCustomers,
  setSuggestedCustomersByEmail,
  setSuggestedCity,
  loadingDone,
  setUnknownAddressFields,
} = newCustomerSlice.actions;


export default newCustomerSlice.reducer;


export const newCustomerSelectors = createSelectors({
  getCurrentData: (rootState: RootStateType): NewCustomerState => rootState.customer.newCustomer,
  getCurrentCustomerData: (rootState: RootStateType): CustomerFormData => rootState.customer.newCustomer.formData,
  getSuggestedCity: (rootState: RootStateType): string | undefined => rootState.customer.newCustomer.suggestedCity,
});
