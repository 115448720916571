import {
  call,
  getContext,
  put,
  select,
  takeLatest
} from 'redux-saga/effects';

import { SagaContextItem } from 'src//store/ReduxSagaContext';
import { subscriptionLinksManagementSelectors } from 'src/subscriptionLinksManagement/redux/subscriptionLinksManagementSelectors';
import {
  clearFilterConstraint,
  clearFilters,
  searchSubscriptionProductLinksAction,
  searchSubscriptionProductLinksFailed,
  searchSubscriptionProductLinksSuccess,
  setFilter
} from 'src/subscriptionLinksManagement/redux/subscriptionLinksManagementSlice';
import {
  CountryValidityConstraint,
  CreationDateConstraint,
  KeywordConstraint,
  PreviousEditDateConstraint,
  SearchSubscriptionProductLinkFilters,
  SearchSubscriptionProductLinkRequest
} from 'src/subscriptionLinksManagement/types/SearchSubscriptionProductLinkRequest';
import { OverviewState } from 'src/subscriptionLinksManagement/types/subscriptionLinksManagement';
import {
  FilterSearchConstraint,
  FilterType,
  SubscriptionLinksStatusRequest
} from 'src/subscriptionLinksManagement/types/SubscriptionProductLinkRequest';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';

import { ApiSubscriptionProductLink } from '../api/apiSubscriptionProduct';
import { SearchSubscriptionProductLinkResponse } from '../types/SearchSubscriptionProductLinkResponse';


export function* fetchSubscriptionProductLinksSaga() {
  try {
    const overviewState: OverviewState = yield select(subscriptionLinksManagementSelectors.getOverviewState);

    const request = buildSearchSubscriptionProductLinkRequest(overviewState);
    const apiSubscriptionProduct: ApiSubscriptionProductLink = yield getContext(SagaContextItem.apiSubscriptionProduct);

    const response: SearchSubscriptionProductLinkResponse = yield call(apiSubscriptionProduct.getSubscriptionProductLinks, request);

    yield put(searchSubscriptionProductLinksSuccess(response));

  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      'oops... failed to fetch subscription product links',
      'hoppla... Beim Abrufen des Abonnement-Produktlinks ist ein Fehler aufgetreten.',
      err
    );
    yield put(searchSubscriptionProductLinksFailed());
  }
}

function mapFilterToFilterRequestObject(filterSearchConstraints: FilterSearchConstraint[], status: SubscriptionLinksStatusRequest): SearchSubscriptionProductLinkFilters {
  const filters: SearchSubscriptionProductLinkFilters = {
    status: status
  };
  filterSearchConstraints.map((filter) => {
    switch (filter.type) {
    case FilterType.CREATION_DATE:
      filters.creationDateConstraint = {
        startDate: filter.startDate,
        endDate: filter.endDate
      } as CreationDateConstraint;
      break;
    case FilterType.PREVIOUS_EDIT_DATE:
      filters.previousEditDateConstraint = {
        startDate: filter.startDate,
        endDate: filter.endDate
      } as PreviousEditDateConstraint;
      break;
    case FilterType.COUNTRY_EXCLUSION:
      filters.countryExclusionConstraint = {
        country: filter.countryCode
      } as CountryValidityConstraint;
      break;
    case FilterType.SEARCH_BY_KEYWORD:
      filters.keywordConstraint = {
        keyword: filter.keyword
      } as KeywordConstraint;
      break;
    default:
      throw new Error('Unhandled filter type');
    }
  });
  return filters;
}

export function buildSearchSubscriptionProductLinkRequest(overviewState: OverviewState): SearchSubscriptionProductLinkRequest {
  return {
    page: overviewState.page,
    size: overviewState.size,
    filters: mapFilterToFilterRequestObject(overviewState.filters.activeFilters, overviewState.filters.status)
  } as SearchSubscriptionProductLinkRequest;
}

export default function* fetchSubscriptionProductLinkWatcher() {
  yield takeLatest(searchSubscriptionProductLinksAction.type, fetchSubscriptionProductLinksSaga);
  yield takeLatest(setFilter.type, fetchSubscriptionProductLinksSaga);
  yield takeLatest(clearFilterConstraint.type, fetchSubscriptionProductLinksSaga);
  yield takeLatest(clearFilters.type, fetchSubscriptionProductLinksSaga);
}
