import {
  call,
  delay,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import {
  closeModal,
  showErrorNotificationWithBackendMessage,
  showSuccessNotification,
} from 'src/redux/app/appSlice';
import {
  deleteBasketItemStatus,
  deleteBasketSuccess,
  updateBasketItemStatus,
  upgradeOrderItem,
  UpgradeOrderItem,
  upgradeOrderItemSuccess,
} from 'src/redux/order/orderEntrySlice';
import { setDisplayUpgradeButton } from 'src/redux/product/productDetailsSlice';
import getProductBySku from 'src/sagas/product/productService';
import { Modals } from 'src/types/Modals';
import { ProductDetails } from 'src/types/product/product';
import { getBasketId } from 'src/utils/basketId';
import { fromSKU } from 'src/utils/mappers/fromSku';

import {
  addItemToBasket,
  AddItemToBasketResult,
  AddItemToBasketStatus,
} from './addItemToBasketService';
import { fetchUpsellsSaga } from './fetchUpsells';
import { trackAddedProductToCartSaga } from './onAddOrderItem';
import { onGetBasketSaga } from './onGetBasket';
import { removeItemFromBasket, RemoveItemFromBasketStatus } from './removeItemFromBasketService';
import { showNotificationAndLogError } from './showNotificationAndLogError';
import getRandomProductAddedConfirmationMessage from '../utils/getRandomProductAddedConfirmationMessage';


export function* upgradeOrderItemSaga({ payload: { item, upgrade, selectedUpgradeVariant } }: UpgradeOrderItem) {
  try {
    const sku = selectedUpgradeVariant ?? upgrade.baseProductNo;

    if (sku) {
      yield put(updateBasketItemStatus({ id: item.id, status: 'in_progress' }));

      const crossSell = upgrade?.crossSell ? { ...upgrade.crossSell, productNo: sku,  sourceType: 'CROSS_SELL' } : undefined;
      const addItemRequest = {
        ...fromSKU(sku),
        source: crossSell,
        crossSell,
      };
      const addItemResult: AddItemToBasketResult = yield call(addItemToBasket, addItemRequest);

      if (addItemResult.status === AddItemToBasketStatus.SUCCESS) {
        const deleteItemStatus = yield call(removeItemFromBasket, item.id);

        if (deleteItemStatus === RemoveItemFromBasketStatus.SUCCESS) {

          const response = yield call(onGetBasketSaga);
          if (response) {
            let mainProduct: ProductDetails | undefined;
            if (!upgrade) {
              mainProduct = yield call(getProductBySku, sku);
            }
            yield call(trackAddedProductToCartSaga, sku, upgrade || mainProduct);
            yield delay(250);

            yield put(upgradeOrderItemSuccess(sku, upgrade));
            yield put(showSuccessNotification(getRandomProductAddedConfirmationMessage()));
          }
        } else if (deleteItemStatus === RemoveItemFromBasketStatus.BASKET_NOT_FOUND) {
          yield put(deleteBasketSuccess());
        }
      } else {
        yield put(showErrorNotificationWithBackendMessage(addItemResult.details));
      }
    }
  } catch (error) {
    yield put(
      logEvent({
        level: LOG_LEVEL.WARN,
        message: `Couldn't upgrade product(sku=${item.baseProductNo} to=${upgrade?.baseProductNo}) in basket ${getBasketId()}`,
        err: error,
      }),
    );
    yield call(showNotificationAndLogError, error);
  }
  finally {
    yield put(deleteBasketItemStatus(item.id));
    yield put(closeModal(Modals.productDetails));
    yield put(setDisplayUpgradeButton(false));
  }
}

export default function* upgradeOrderItemWatcher() {
  yield takeEvery(upgradeOrderItem.type, upgradeOrderItemSaga);
  yield takeEvery(upgradeOrderItemSuccess.type, fetchUpsellsSaga);
}
