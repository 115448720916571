import {
  put,
  takeLatest,
  call,
  getContext,
  select,
} from 'redux-saga/effects';

import { ApiVirtualVoucher } from 'src/api/apiVirtualVoucher';
import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal, showErrorNotificationWithBackendMessage } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { editOrderSelector } from 'src/redux/editOrder/editOrderSlice';
import { getVouchersSuggestionsSuccess, getVouchersSuggestions } from 'src/redux/order/voucherSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';
import { VoucherSuggestionsResponse } from 'src/types/voucher/SuggestedVoucherResponse';


const { getOriginalOrder } = editOrderSelector;

const { getCustomerExternalId } = customerInfoSelectors;

const { getCurrentCountry } = appSelectors;

export function* getVouchersSuggestionsSaga() {
  try {
    const apiVirtualVoucher: ApiVirtualVoucher = yield getContext(SagaContextItem.apiVirtualVoucher);

    const editedOriginalOrder = yield select(getOriginalOrder);
    const externalCustomerId = yield select(getCustomerExternalId);
    const countryValidity = yield select(getCurrentCountry);

    const response: VoucherSuggestionsResponse = yield call(apiVirtualVoucher.getSuggestedVouchers, {
      externalCustomerId: editedOriginalOrder ? editedOriginalOrder?.externalCustomerId : externalCustomerId,
      countryValidity,
    });

    yield put(getVouchersSuggestionsSuccess(response as VoucherSuggestionsResponse));
    yield put(openModal(Modals.voucherSuggestions));

  } catch (err) {
    yield put(logErrorEvent({ message: 'Could fetch vouchers suggestions', err }));
    yield put(showErrorNotificationWithBackendMessage(err.response?.data?.detail));
  }
}


export default function* getVouchersSuggestionsWatcher() {
  yield takeLatest(getVouchersSuggestions.type, getVouchersSuggestionsSaga);
}
