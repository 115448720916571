export default {
  'waitlist.step.login' : 'Kundennummer eingeben',
  'waitlist.step.birthday.check' : 'Authentifizierung',
  'waitlist.step.login.hint': '„Die Lieferung erfolgt innerhalb von 6 Wochen an Ihre hinterlegte Lieferadresse, falls das Produkt wieder verfügbar ist.“',
  'waitlist.step.login.text' : '„Nennen Sie mir bitte zuerst Ihre Kundennummer.”',
  'waitlist.step.birthday.check.text' : '„Nennen Sie mir zum Datenabgleich bitte Ihr Geburtsdatum (Passwort).”',
  'waitlist.step.confirmed' : 'Danke für Ihren Wartelisteneintrag',
  'waitlist.back.login' : 'Zurück zur Kundennummer',
  'waitlist.confirmed.hint': '„Die Lieferung erfolgt innerhalb von 6 Wochen, falls das Produkt wieder verfügbar ist.“',
  'waitlist.step.checkout': 'Wartelisteneintrag',
  'waitlist.step.checkout.text': '"Sollte das Produkt innerhalb der nächsten 6 Wochen wieder lieferbar sein, wird es automatisch an Ihre hinterlegte Lieferadresse geschickt. Es gilt der heutige Preis und Sie zahlen bequem nach Erhalt der Ware."',
  'waitlist.step.checkout.save': 'In Warteliste eintragen',
  'waitlist.choseDeliveryAddress': 'Lieferadresse derzeit nicht belieferbar. Bitte andere Adresse wählen',

  // waitlist history page
  'waitlist.history.title': 'Wartelisteneinträge',
  'waitlist.history.status.CREATED': 'Wartelisteneintrag aktiv',
  'waitlist.history.status.PROCESSED': 'Auftrag angelegt',
  'waitlist.history.status.CANCELLED': 'Storniert',
  'waitlist.history.status.FAILED': 'Fehlgeschlagen',
  'waitlist.history.status.REJECTED': 'Auftrag abgewiesen',
  'waitlist.history.check_order': 'Bestellung prüfen',
  'waitlist.history.header.entry_date': 'Erstellungsdatum',
  'waitlist.history.header.creation_date': 'Bestelldatum',
  'waitlist.CancellationReason':'Stornogrund',
  'waitlist.CancellationReason.CUSTOMER_REQUEST':'Kundenwunsch',
  'waitlist.CancellationReason.DELIVERY_NOT_POSSIBLE':'Belieferung nicht möglich',
  'waitlist.CancellationReason.DETECTION_ERROR':'Erfassungsfehler',
  'waitlist.CancellationReason.WAITLIST_TIMEOUT':'Wartelisten-Zeitüberschreitung',
  'waitlist.CancellationReason.selectorLabel':'Einen Stornogrund auswählen ',
  'waitlist.CancellationReason.closeButton':'Abbrechen',
  'waitlist.CancellationReason.submitButton':'Warelisten stornieren',
  'waitlist.CancellationReason.question':'Warum möchten Sie den Wartelisteneintrag stornieren?',
  'waitlist.CancellationReason.modalTitle':'Den Wartelisteneintrag stornieren ',
  'waitlist.failedreason': 'Fehlgeschlagener Grund',
  'waitlist.rejectionReason': 'Fehlermeldung',
  'waitlist.info': 'Wartelisteinfo',
};
