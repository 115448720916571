import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { closeModal, openModal } from 'src/redux/app/appSlice';
import {
  addDeliveryAddressSuccess,
  birthDayCheckConfirmed,
  fetchCustomerFinished,
  fetchCustomerInProgress,
  fetchCustomerSuccess,
  resetPostCustomerAuthCheckData,
  setPreviouslyUsedBankAccount,
  setSelectedCustomerLocalId,
  setSelectedPreferredPayment,
  storeSelectedDeliveryAddressOrUseBilling
} from 'src/redux/customer/customerInfoSlice';
import { checkCustomerPinSuccessful, skipPinChallengeSuccess } from 'src/redux/customer/CustomerPinSlice';
import { resetCustomers } from 'src/redux/customer/customerSearchSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { customerPinSelector } from 'src/redux/customer/selectors/customerPinSelectors';
import { fetchSubscriptionHistory } from 'src/redux/customer/subscriptionHistorySlice';
import { getOffer, loadReklaOrder } from 'src/redux/order/orderEntrySlice';
import { resetVoucher } from 'src/redux/order/voucherSlice';
import {
  DEFAULT_PAYMENTS,
  getBankingDetailsSuccess,
  resetInstallmentPayment,
  resetPayment,
  resetSelectedPaymentMethod,
  storeAvailablePaymentMethods,
  storeSelectedPaymentMethod,
} from 'src/redux/payment/paymentSlice';
import {
  checkAndOpenReceptionSurvey,
  isConsentNotYetQueried,
  loadAlreadyManagedConsents,
} from 'src/sagas/customer/authenticateCustomerUtils';
import { shouldStorePreferredPaymentMethod } from 'src/sagas/customer/fetchCustomerUtils';
import { findAndSelectDefaultDeliveryAddress } from 'src/sagas/customer/setSelectedAddress';
import { DeliveryAddress } from 'src/types/customer/address';
import { Customer, MarketResearchConsent } from 'src/types/customer/customer';
import { PostCustomerAuthCheckData } from 'src/types/customer/PostCustomerAuthCheckData';
import { ReceptionProvider } from 'src/types/customer/ReceptionSurveyQuestionsData';
import { Modals } from 'src/types/Modals';
import { FetchSubscriptionsKeywordCriteriaType, SubscriptionStatusRequest } from 'src/types/subscription/SubscriptionsOverviewRequest';
import { CustomerType } from 'src/types/voucher/EmployeeDiscount';
import { containsSimilarAddress } from 'src/utils/formatters/formatAddressToString';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';


export function* loadLoggedInCustomerSaga(action?: PayloadAction) {
  const loadedCustomer: Customer = yield select(customerPinSelector.getPreLoadedCustomer);
  try {
    const customer = yield call(loadNecessaryCustomerData, loadedCustomer);

    const postCustomerAuthCheckRequested: boolean = yield call(handlePostCustomerAuthCheck, customer);
    if (postCustomerAuthCheckRequested) {
      return;
    }

    yield call(loadNormalOffer, customer);
    yield call(handleNextStep, customer);
  } catch (err) {
    yield put(logErrorEvent({
      message: `Could not load logged in customer ${loadedCustomer?.id}, action call: ${action?.type}`,
      err
    }));
  } finally {
    yield put(fetchCustomerFinished());
  }
}

function* loadNecessaryCustomerData(loadedCustomer: Customer) {
  yield put(fetchCustomerInProgress());
  window.sessionStorage.removeItem('customerId');

  const customer: Customer = yield call(checkAndLoadLocalCustomerInfo, loadedCustomer);
  yield call(findAndSelectDefaultDeliveryAddress, customer);
  yield put(fetchCustomerSuccess(reformatBirthdayInResponse(customer)));

  if (customer.bankingDetails) {
    yield put(getBankingDetailsSuccess({ bankingDetails: customer.bankingDetails }));
  }

  // TODO: centralize locally stored info in one place ;-> localCustomerStorage
  yield call(loadAlreadyManagedConsents, customer);
  yield put(resetCustomers());
  yield put(closeModal(Modals.customerSearch));

  return customer;
}

function* handlePostCustomerAuthCheck(customer: Customer) {
  const postCustomerAuthCheckData: PostCustomerAuthCheckData | undefined = yield select(customerInfoSelectors.getPostCustomerAuthCheckData);
  if (!postCustomerAuthCheckData) {
    return false;
  }

  let postCustomerAuthCheckRequested = false;
  const { reklaOrder, openOrdersHistory, subscriptions } = postCustomerAuthCheckData;

  if (openOrdersHistory?.limelightOrderId) {
    yield put(resetPostCustomerAuthCheckData());
    window.sessionStorage.setItem('customerId', customer.externalCustomerId);
    yield put(push(`/orderhistory/${customer.externalCustomerId}?orderId=${openOrdersHistory.limelightOrderId}`));
    return true;
  }

  if (reklaOrder) {
    yield put(loadReklaOrder(reklaOrder));
    postCustomerAuthCheckRequested = true;
  }

  if(subscriptions){
    yield put(fetchSubscriptionHistory({ externalCustomerId: customer.externalCustomerId, criteria: { keyword: subscriptions.baseProductNoFilter, status: SubscriptionStatusRequest.ACTIVE, limit: 1, keywordType: FetchSubscriptionsKeywordCriteriaType.SUBSCRIPTION_PRODUCT_NO } }));
    yield put(resetPostCustomerAuthCheckData());
  }

  if (postCustomerAuthCheckRequested) {
    yield put(resetPostCustomerAuthCheckData());
    return true;
  }

  return false;
}

export function* checkAndLoadLocalCustomerInfo(customer: Customer) {
  const updatedCustomer = { ...customer };
  const savedCustomer = localCustomerStorage.getLastCustomerByExternalId(updatedCustomer.externalCustomerId);
  if(!savedCustomer) {
    const localCustomerId = localCustomerStorage.saveCustomer(updatedCustomer);
    yield put(setSelectedCustomerLocalId(localCustomerId));
    return updatedCustomer;
  }

  yield put(setSelectedCustomerLocalId(savedCustomer.id));

  if (!updatedCustomer.dateOfBirth && savedCustomer.customer?.dateOfBirth) {
    updatedCustomer.dateOfBirth = savedCustomer.customer.dateOfBirth;
  }

  if (updatedCustomer.reception === undefined || updatedCustomer.reception === ReceptionProvider.NOT_ASKED) {
    updatedCustomer.reception = savedCustomer.reception;
  }

  if (containsSimilarAddress(updatedCustomer.deliveryAddresses, savedCustomer.deliveryAddress!)) {
    localCustomerStorage.removeLastNewDeliveryAddress(savedCustomer.id);
  } else {
    yield put(addDeliveryAddressSuccess(savedCustomer.deliveryAddress));
  }

  return updatedCustomer;
}

export function* loadNormalOffer(customer: Customer) {
  yield put(resetSelectedPaymentMethod());
  yield put(resetInstallmentPayment());
  yield put(storeAvailablePaymentMethods(DEFAULT_PAYMENTS));
  if (customer.bankingDetails) {
    yield put(setPreviouslyUsedBankAccount());
  } else {
    yield put(resetPayment());
  }
  storeSelectedDeliveryAddressOrUseBilling(customer.deliveryAddresses?.find((a: DeliveryAddress) => a.isDefault));
  const goodPreferredPayment = shouldStorePreferredPaymentMethod(customer) ? customer.preferredPaymentMethod : undefined;
  yield put(setSelectedPreferredPayment(goodPreferredPayment));
  yield put(storeSelectedPaymentMethod(goodPreferredPayment));
  if (customer?.type && customer.type !== CustomerType.REGULAR) {
    yield put(resetVoucher());
  } else {
    yield put(getOffer());
  }
}

export function* handleNextStep(customer: Customer) {
  const nextStep = yield select(customerPinSelector.getNextStep);
  if (nextStep === Modals.consentManagement) {
    const consentNotAllowed = customer.emailMarketResearchConsent === MarketResearchConsent.NOT_ALLOWED
      || customer.marketResearchConsent === MarketResearchConsent.NOT_ALLOWED;
    if (isConsentNotYetQueried(customer.marketResearchConsent, consentNotAllowed)) {
      yield put(openModal(nextStep));
    } else {
      yield call(checkAndOpenReceptionSurvey, customer);
    }
  }
}

export default function* fetchCustomerWatcher() {
  yield takeLatest(checkCustomerPinSuccessful.type, loadLoggedInCustomerSaga);
  yield takeLatest(skipPinChallengeSuccess.type, loadLoggedInCustomerSaga);
  yield takeLatest(birthDayCheckConfirmed.type, loadLoggedInCustomerSaga);
}
