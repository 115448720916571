import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import getProductBySku from 'src/sagas/product/productService';
import {
  closeModal,
  openModal,
  resetSearchByBnr,
  searchByBnrAction,
  SearchByBnrAction,
  searchByBnrForEditFail,
  searchByBnrForEditSuccess,
  searchByBnrSuccess
} from 'src/subscriptionLinksManagement/redux/subscriptionLinksManagementSlice';
import { SubscriptionProductLinkManagementModals } from 'src/subscriptionLinksManagement/utils/modals';
import { ProductDetails } from 'src/types/product/product';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


function* searchByBnrSaga(action: SearchByBnrAction) {
  try {
    const { bnr, forEdit } = action.payload;
    const response: ProductDetails = yield call(getProductBySku, bnr.replace(/ /g, ''));
    if (response) {
      if (forEdit) {
        yield put(searchByBnrForEditSuccess(response));
      } else {
        yield put(searchByBnrSuccess(response));
        yield put(closeModal(SubscriptionProductLinkManagementModals.searchByBnr));
        yield put(openModal(SubscriptionProductLinkManagementModals.createSubscriptionProductLink));
      }
    }
  } catch (err) {
    const { forEdit } = action.payload;
    yield put(resetSearchByBnr());
    if (forEdit) {
      yield put(searchByBnrForEditFail());
    }
    yield call(
      logErrorAndShowNotification,
      'oops... failed to search by given BNR',
      'hoppla... Suche nach angegebener BNR fehlgeschlagen.',
      err
    );
  }
}

export default function* searchByBnrWatcher() {
  yield takeLatest(searchByBnrAction.type, searchByBnrSaga);
}