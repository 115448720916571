import axios, { AxiosInstance } from 'axios';

import { ContentfulConfig } from 'src/config';
import { NotificationsResponse } from 'src/types/NotificationResponse';


export interface ApiNotifications {
  client: AxiosInstance;
  getNotifications: () => Promise<NotificationsResponse | undefined>
}

export function getApiNotifications(contentfulConfig: ContentfulConfig): ApiNotifications {
  const notificationsClient = axios;

  return {
    client: notificationsClient,
    getNotifications: async (): Promise<NotificationsResponse | undefined> => {
      const graphQLQuery = `{
      wilisPage(id: "${contentfulConfig.wilisPageId}", preview: false) {
        title
        notificationsCollection {
          items {
            publishedFrom
            publishedTo
            type
            detail
            sys {
              id
            }
          }
        }
      }
    }`;

      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await notificationsClient.get(
        contentfulConfig.baseUrl,
        {
          headers,
          params: {
            query: graphQLQuery,
          },
        },
      );

      if (!response) {
        return;
      }

      const { data: { data = {} } = {} } = response;

      return {
        title: data.wilisPage.title,
        notifications: data.wilisPage.notificationsCollection.items,
      };
    },
  };
}
