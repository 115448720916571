export default {
  //Source
  'source.channelId':'Vertriebsweg',
  'source.windowId' : 'Fenster',
  'source.TV': 'TV',
  'source.Internet': 'Internet',
  'source.channel': 'Eingangskanal',
  'source.ECOA': 'Internet automatisch',
  'source.ECOM': 'Internet manuell',
  'source.IVR': 'Easy',
  'source.KIKS': 'KiKService (CRM)',
  'source.MAIL': 'E-Mail',
  'source.OUTB': 'Outbound',
  'source.SCHR': 'Schriftlich',
  'source.TELE': 'Telefon',
  'source.undefined': '-',
};
