export default {
  'subscriptionProductLink.newProductLinkButton': 'Neu Produkt',

  'subscriptionProductLink.productSearch.title': 'Produkt suchen',
  'subscriptionProductLink.productSearch.searchButton': 'Suchen',
  'subscriptionProductLink.productSearch.bnrInput': 'Lifer BNR',
  
  'subscriptionProductLink.createProductLink.backToSearch': 'Zurück zur Suche',
  
  // create/edit form
  'subscriptionProductLink.createEditProductLinkForm.searchResults.title': 'Suchergebnis:',
  'subscriptionProductLink.createEditProductLinkForm.information.bnr': 'Bestellnr. {BNR}',
  'subscriptionProductLink.createEditProductLinkForm.information.title': 'Angaben:',
  'subscriptionProductLink.createEditProductLinkForm.information.price': 'Bestellnr. {BNR}',
  'subscriptionProductLink.createEditProductLinkForm.information.deliveryProductInputLabel': 'Werbe BNR',
  'subscriptionProductLink.createEditProductLinkForm.information.subscriptionProductInputLabel': 'Lifer BNR',
  'subscriptionProductLink.createEditProductLinkForm.information.shopProductInputLabel': 'Einmalige BNR {BNR} (Optional)',
  'subscriptionProductLink.createEditProductLinkForm.information.shopProductInputHint': 'Weitere einmalige Nummern zu diesem Produkt addieren',
  'subscriptionProductLink.createEditProductLinkForm.information.checkInput': 'Bitte BNR prüfen',
  
  'subscriptionProductLink.createEditProductLinkForm.submit': 'Produkt anlegen',
  'subscriptionProductLink.createEditProductLinkForm.submitEdit': 'Speichern',
  'subscriptionProductLink.createEditProductLinkForm.abort': 'Abbrechen',

  // Table
  'subscriptionProductLink.table.header.bnr': 'Werte BNR',
  'subscriptionProductLink.table.header.description': 'Abobezeichnung',
  'subscriptionProductLink.table.header.itemNumber': 'Liefer BNR',
  'subscriptionProductLink.table.header.originalBnr': 'Einmalige BNR',
  'subscriptionProductLink.table.header.articleDescription': 'Artikelbezeichnung',
  'subscriptionProductLink.table.header.price': 'Preis',
  'subscriptionProductLink.table.header.status': 'Status',
  'subscriptionProductLink.table.header.modified': 'Geändert',
  'subscriptionProductLink.table.header.created': 'Angelegt',
  'subscriptionProductLink.table.header.country': 'Länderausschluss',
  'subscriptionProductLink.table.body.country.DE': 'Deutschland',
  'subscriptionProductLink.table.body.country.AT': 'Österreich',
  'subscriptionProductLink.table.body.editBtn': 'Bearbeiten',
  
  'subscriptionProductLink.status.ALL': 'Alle Produkte',
  'subscriptionProductLink.status.ACTIVE': 'Aktiv',
  'subscriptionProductLink.status.EXPIRED': 'Ausgelaufen',
  'subscriptionProductLink.status.STOPPED': 'Gestoppt',
  'subscriptionProductLink.status.DELETED': 'Gelöscht',

  'subscriptionProductLink.filter.input.lable': 'Filtern:',
  'subscriptionProductLink.filter.input.NONE': 'Filter wählen',
  'subscriptionProductLink.filter.input.COUNTRY_EXCLUSION': 'Länderausschluss',
  'subscriptionProductLink.filter.input.CREATION_DATE': 'Creation date',
  'subscriptionProductLink.filter.input.PREVIOUS_EDIT_DATE': 'Previous edit date',
  'subscriptionProductLink.filter.input.SEARCH_BY_KEYWORD': 'Nach Bestellnr, Produkt suchen',

  'subscriptionProductLink.filter.input.search': 'Nach Bestellnr, Produkt suchen',
  'subscriptionProductLink.filter.input.startDate': 'Startdatum (tt.mm.jjjj)',
  'subscriptionProductLink.filter.input.endDate': 'Enddatum (tt.mm.jjjj)',

  'subscriptionProductLink.periodFilters.YESTERDAY': 'Gestern',
  'subscriptionProductLink.periodFilters.LAST_WEEK': 'Letzte Woche',
  'subscriptionProductLink.periodFilters.LAST_MONTH': 'Letzter Monat',
  'subscriptionProductLink.periodFilters.LAST_SIX_MONTHS': 'Letzten 6 Monate',
  'subscriptionProductLink.periodFilters.LAST_YEAR': 'Letztes Jahr',
  'subscriptionProductLink.periodFilters.CUSTOM': 'Datum eingeben',

  'subscriptionProductLink.countryExclusion.label': 'Länderausschluss',
  'subscriptionProductLink.countryExclusion.DE': 'Deutschland',
  'subscriptionProductLink.countryExclusion.AT': 'Österreich',

  'subscriptionProductLink.filter.clearAll': 'Alle Filter löschen',
  
  'subscriptionProductLink.createSubscriptionProductLinkSaga.successNotificationHint': 'Der Abonnement-Produktlink wurde erfolgreich erstellt',
} as { [key: string]: string };
