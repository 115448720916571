import axios from 'axios';
import jwt from 'jsonwebtoken';
import jwkToBuffer from 'jwk-to-pem';

import { DecodedJWT } from 'src/types/Auth';
import { capitalizeFirstLetter } from 'src/utils/string/capitalizeFirstLetter';

import Config from '../config';


export interface AgentDataInfo {
  fullName: string;
  roles: string[];
  username: string;
}

const JWTParser= (JWT: string): DecodedJWT => {
  return JSON.parse(decodeURIComponent(escape(atob(JWT.split('.')[1]))));
};

export const getCognitoRolesFromJWT = (JWT: string): string[] => {
  try {
    if (isJwtValidUsingJWKS(JWT)) {
      const decodedJWT: DecodedJWT = JWTParser(JWT);
      return decodedJWT['cognito:roles']?.map(e => e.split('role/')[1]) || [];
    }
    return [];
  } catch(e) {
    return [];
  }
};

export const getCognitoFullNameFromJWT = (JWT: string | null): string => {
  try {
    if (JWT && isJwtValidUsingJWKS(JWT)) {
      const decodedJWT: DecodedJWT = JWTParser(JWT);
      return `${capitalizeFirstLetter(decodedJWT.given_name)} ${capitalizeFirstLetter(decodedJWT.family_name)}`;
    }
    return '';
  } catch (e) {
    return '';
  }
};

export const getAgentDataInfo = (JWT: string | null): AgentDataInfo | undefined => {
  try {
    if (JWT && isJwtValidUsingJWKS(JWT)) {
      const decodedJWT: DecodedJWT = JWTParser(JWT);
      return {
        fullName: `${capitalizeFirstLetter(decodedJWT.given_name)} ${capitalizeFirstLetter(decodedJWT.family_name)}`,
        roles: decodedJWT['cognito:roles']?.map(e => e.split('role/')[1]) || [],
        username: decodedJWT['cognito:username']??'__',
      };
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const getAgentUsernameFromJWT = async (JWT: string): Promise<string| undefined> => {
  try {
    const isValid = await isJwtValidUsingJWKS(JWT);
    if (isValid) {
      const decodedJWT: DecodedJWT = JWTParser(JWT);
      return decodedJWT['cognito:username'];
    }
    return undefined;
  } catch(e) {
    return undefined;
  }
};


interface TokenHeader {
  kid: string;
  alg: string;
}

interface RSA {
  alg: 'RS256';
  e: string;
  kid: string;
  kty: 'RSA';
  n: string;
  use: string;
}

export const isJwtValidUsingJWKS = async (token: string) => {
  try {
    const publicKey = await getPublicKey(token);
    jwt.verify(token, publicKey, { algorithms: ['RS256'], ignoreExpiration: true });
    return true;
  } catch(e) {
    return false;
  }
};

export const getPublicKey = async (token: string) => {
  const jwtHeader: TokenHeader = JSON.parse(atob(token.split('.')[0]));
  const jwk = await getJwksJson();

  const value = jwk.data.keys.find((v: RSA) => v.kid === jwtHeader.kid);
  return jwkToBuffer(value) + '';
};

export async function getJwksJson() {
  return await axios.get(`${Config.env.cognito.jwksUrl}/${Config.env.userPoolId}/.well-known/jwks.json`);
}


export default JWTParser;
