import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import {
  closeModal,
  showErrorNotificationWithBackendMessage,
  showSuccessNotification
} from 'src/redux/app/appSlice';
import { editOrderSelector, RedeemVoucher } from 'src/redux/editOrder/editOrderSlice';
import { voucherSelector } from 'src/redux/order/selectors/voucherSelectors';
import {
  submitSuggestedVoucher,
  submitSuggestedVoucherDone,
  submitVoucherFailed,
  submitVoucherSuccess,
} from 'src/redux/order/voucherSlice';
import { resetServiceVoucher } from 'src/redux/serviceVoucher/serviceVoucherActions';
import { redeemVoucherSaga } from 'src/sagas/editOrder/redeemVoucher';
import { Modals } from 'src/types/Modals';
import { EditOrderActionRequestWithOrderId, EditOrderActionType } from 'src/types/orderhistory/EditOrderActionRequest';

import { checkAndResetPayment, getOfferAndStoreBasket as getOfferSaga } from '../offer/getOffer';


const { getEditedOrderId } = editOrderSelector;
const { getVoucherErrorDetail } = voucherSelector;

export function* submitSuggestedVoucherSaga(action: PayloadAction<string>) {
  try {
    const editedOrderId = yield select(getEditedOrderId);

    if (editedOrderId) {
      yield call(redeemSuggestedVoucherForEditedOrder, editedOrderId, action.payload);
      return;
    }

    const offer = yield call(getOfferSaga);
    const voucher = offer?.response?.offer?.voucher;

    if (voucher) {
      yield put(
        submitVoucherSuccess(voucher),
      );
      if (yield call(checkAndResetPayment)) {
        yield call(getOfferSaga);
      }
      yield put(closeModal(Modals.voucherSuggestions));
      yield put(showSuccessNotification('order.voucher.submited'));
    } else {
      const voucherErrorDetail = yield select(getVoucherErrorDetail);

      yield put(showErrorNotificationWithBackendMessage(voucherErrorDetail));
      yield put(submitVoucherFailed());
      yield put(resetServiceVoucher());
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not redeem suggested voucher ${action.payload}`, err }));
  }
}

function* redeemSuggestedVoucherForEditedOrder(editedOrderId: string, code: string) {
  const payload: EditOrderActionRequestWithOrderId = {
    orderId: editedOrderId,
    actionRequest: {
      type: EditOrderActionType.REDEEM_VOUCHER,
      code: code,
    },
  };

  const redeemedSuccessfully = yield call(redeemVoucherSaga, { payload } as RedeemVoucher);

  if (redeemedSuccessfully) {
    yield put(closeModal(Modals.voucherSuggestions));
    yield put(showSuccessNotification('order.voucher.submited'));
  }

  yield put(submitSuggestedVoucherDone());
}


export default function* submitSuggestedVoucherWatcher() {
  yield takeLatest(submitSuggestedVoucher.type, submitSuggestedVoucherSaga);
}
