import chOrderEntrWatcher from 'src/CHOrderEntry/sagas/index';
import editedOrderWatchers from 'src/editedorder/sagas/index';
import loggingSagasWatcher from 'src/logging/loggingSaga';
import cancelRejectedOrderWatcher from 'src/OrderDisplay/sagas/cancelRejectedOrder';
import fetchOrderWatcher from 'src/OrderDisplay/sagas/fetchOrder';
import watchOpenOrderInfo from 'src/OrderDisplay/sagas/openOrderInfo';
import prepareOrderRecreationWatcher from 'src/OrderDisplay/sagas/prepareOrderRecreationSaga';
import resubmitOrderWatcher from 'src/OrderDisplay/sagas/resubmitOrder';
import updateOrderWatcher from 'src/OrderDisplay/sagas/updateOrder';
import { performOrderPreChecksWatcher } from 'src/orderPreCheck/sagas/performOrderPreChecks';
import letterPreviewWatcher from 'src/previewLetter/sagas/previewLetterSaga';
import loadAuthenticatedAgentWatcher from 'src/sagas/auth/loadAuthenticatedAgent';
import validateAuthenticationTokenWatcher from 'src/sagas/auth/validateAuthenticationToken';
import createSubscriptionSagas from 'src/sagas/createSubscription';
import createWaitlistItemSaga from 'src/sagas/createWaitlistItem';
import customerPinVerification from 'src/sagas/customerPinVerification';
import sendRingsTemplateLetterWatcher from 'src/sagas/infopost/sendRingsTemplateLetter';
import storeCustomerLocallyWatcher from 'src/sagas/orderEntry/storeCustomerLocally';
import { fetchOrderInfoByLimelightOrderIdWatcher } from 'src/sagas/orderHistory/fetchOrderInfoByLimelightOrderId';
import recreateOrder from 'src/sagas/recreateOrder';
import cancelSubscriptionItemWatcher from 'src/sagas/subscriptionHistory/cancelSubscriptionItem';
import submitEditedSubscriptionWatcher from 'src/sagas/subscriptionHistory/submitEditedSubscription';
import cancelWaitlistItemWatcher from 'src/sagas/waitlistHistory/cancelWaitlistItem';
import createSubscriptionProductLinkWatcher from 'src/subscriptionLinksManagement/sagas/createSubscriptionProductLink';
import editSubscriptionProductLinkWatcher from 'src/subscriptionLinksManagement/sagas/editSubscriptionProductLink';
import fetchSubscriptionProductLinkWatcher from 'src/subscriptionLinksManagement/sagas/fetchSubscriptionProductLinks';
import initEditSubscriptionProductLinkWatcher from 'src/subscriptionLinksManagement/sagas/initEditSubscriptionProductLink';
import searchByBnrWatcher from 'src/subscriptionLinksManagement/sagas/searchByBnr';
import subscriptionsManagementWatcher from 'src/subscriptionProductManagement/sagas';

import getSuggestedCityWatcher from './addressSuggestions/getSuggestedCitySaga';
import { watchGetNotificationsSaga } from './app/getNotifications';
import onChangeCountryWatcher from './app/onChangeCountry';
import fetchOauthTokensWatcher from './auth/fetchOauthTokens';
import fetchBotRequestByNumberWatcher from './bot/fetchBotRequestByNumberSaga';
import customerSagas from './customer';
import getCustomersWatcher from './customer/getCustomers';
import editOrderSagas from './editOrder';
import inflightOrder from './inflightOrder';
import getOfferWatcher from './offer/getOffer';
import offersSagas from './offers';
import orderEntrySagas from './orderEntry';
import newOrderWatcher from './orderEntry/newOrder';
import { cancelOrderWatcher } from './orderHistory/cancelOrder';
import fetchOrdersOverviewHistoryWatcher from './orderHistory/fetchOrdersOverviewHistory';
import paymentSagas from './payment';
import productDetailsSaga from './productDetails/index';
import productSearchSagas from './productSearch';
import fetchProductStockWatcher from './productStock/fetchProductStock';
import fetchProductSuggestionsWatcher from './productSuggestions/fetchProductSuggestions';
import sessionSagas from './session';
import editMultipleSubscriptionNumbersWatcher from './subscriptionHistory/editMultipleSubscriptionNumbers';
import fetchSubscriptionHistoryWatcher from './subscriptionHistory/fetchSubscriptionHistory';
import submitEditedSubscriptionNumberWatcher from './subscriptionHistory/submitEditedSubscriptionNumber';
import voucherSagas from './voucher';
import fetchWaitlistHistoryWatcher from './waitlistHistory/fetchWaitlistHistory';


export default [
  ...productDetailsSaga,
  ...orderEntrySagas,
  ...customerSagas,
  ...paymentSagas,
  ...voucherSagas,
  ...productSearchSagas,
  ...offersSagas,
  ...sessionSagas,
  ...editOrderSagas,
  ...inflightOrder,
  ...editedOrderWatchers,
  ...createWaitlistItemSaga,
  ...createSubscriptionSagas,
  ...customerPinVerification,
  ...recreateOrder,
  ...subscriptionsManagementWatcher,
  ...chOrderEntrWatcher,
  fetchOrderWatcher,
  updateOrderWatcher,
  resubmitOrderWatcher,
  prepareOrderRecreationWatcher,
  fetchOauthTokensWatcher,
  loadAuthenticatedAgentWatcher,
  validateAuthenticationTokenWatcher,
  watchOpenOrderInfo,
  watchOpenOrderInfo,
  getCustomersWatcher,
  getOfferWatcher,
  newOrderWatcher,
  fetchProductSuggestionsWatcher,
  fetchProductStockWatcher,
  onChangeCountryWatcher,
  fetchOrdersOverviewHistoryWatcher,
  fetchWaitlistHistoryWatcher,
  cancelOrderWatcher,
  cancelRejectedOrderWatcher,
  watchGetNotificationsSaga,
  loggingSagasWatcher,
  cancelWaitlistItemWatcher,
  fetchSubscriptionHistoryWatcher,
  submitEditedSubscriptionWatcher,
  editMultipleSubscriptionNumbersWatcher,
  submitEditedSubscriptionNumberWatcher,
  cancelSubscriptionItemWatcher,
  sendRingsTemplateLetterWatcher,
  letterPreviewWatcher,
  performOrderPreChecksWatcher,
  fetchOrderInfoByLimelightOrderIdWatcher,
  getSuggestedCityWatcher,
  fetchBotRequestByNumberWatcher,
  storeCustomerLocallyWatcher,
  fetchSubscriptionProductLinkWatcher,
  createSubscriptionProductLinkWatcher,
  editSubscriptionProductLinkWatcher,
  initEditSubscriptionProductLinkWatcher,
  searchByBnrWatcher
];
