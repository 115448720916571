import { AxiosInstance } from 'axios';
import { decamelizeKeys } from 'humps';

import { getHttpClient } from 'src/api/httpClient/HttpClient';
import { localeHeaderInterceptor } from 'src/api/httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from 'src/api/httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from 'src/api/httpClient/interceptors/xAuthorizationHeaderInterceptor';
import { SearchSubscriptionProductLinkRequest } from 'src/subscriptionLinksManagement/types/SearchSubscriptionProductLinkRequest';
import { SearchSubscriptionProductLinkResponse } from 'src/subscriptionLinksManagement/types/SearchSubscriptionProductLinkResponse';
import { EditSubscriptionProductLinkRequest, SubscriptionProductLinkRequestWrapper } from 'src/subscriptionLinksManagement/types/SubscriptionProductLinkRequest';


export interface ApiSubscriptionProductLink {
  axiosInstance: AxiosInstance;
  getSubscriptionProductLinks: (request: SearchSubscriptionProductLinkRequest) => Promise<SearchSubscriptionProductLinkResponse>;
  createSubscriptionProductLink: (request: SubscriptionProductLinkRequestWrapper) => Promise<void>;
  editSubscriptionProductLink: (id: string, requestBody: EditSubscriptionProductLinkRequest) => Promise<void>;
}

export function getSubscriptionProductLinkApi(
  baseUrl: string,
): ApiSubscriptionProductLink {
  const axiosInstance = getHttpClient({
    baseUrl,
    transform: {
      camelize: true,
    },
    interceptors: {
      response: refreshTokenInterceptor,
      request: [localeHeaderInterceptor, xAuthorizationHeaderInterceptor],
    },
  });

  return {
    axiosInstance: axiosInstance,
    getSubscriptionProductLinks: async (req: SearchSubscriptionProductLinkRequest) => {
      const response = await axiosInstance.post(
        '/api/subscription-product-link/search',
        decamelizeKeys(req),
      );
      return response.data;
    },
    createSubscriptionProductLink: async (req: SubscriptionProductLinkRequestWrapper) => {
      await axiosInstance.post(
        '/api/subscription-product-link',
        decamelizeKeys(req),
      );
    },
    editSubscriptionProductLink: async (id: string, requestBody: EditSubscriptionProductLinkRequest) => {
      await axiosInstance.put(
        `/api/subscription-product-link/${id}`,
        decamelizeKeys(requestBody),
      );
    },
  };
}
