/* eslint-disable */

function isNetworkError(err: any) {
  return extractXhrStatus(err) === 0 || err?.code === 'ERR_NETWORK' || err?.message?.includes('Network Error');
}

function isBadRequestStatus(err: any) {
  return extractResponseStatus(err) === 400;
}

function isUnauthorizedStatus(err: any) {
  return extractResponseStatus(err) === 401;
}

function isForbiddenStatus(err: any) {
  return extractResponseStatus(err) === 403;
}

function isNotFoundStatus(err: any) {
  return extractResponseStatus(err) === 404;
}

function isConflictStatus(err: any) {
  return extractResponseStatus(err) === 409;
}

function isTimeoutStatus(err: any) {
  return extractResponseStatus(err) === 504;
}

const extractXhrStatus = (err: any) => err?.request?.__sentry_xhr__?.status_code;
const extractResponseStatus = (err: any) => err?.response?.status;
const extractDetails = (err: any) => err?.response?.data?.details ?? err?.response?.data?.detail ?? err?.response?.details ?? err?.response?.detail;
const extractErrorKey = (err: any) => (err?.response?.data?.key ?? err?.response?.key)?.toLowerCase();

export {
  isNetworkError,
  isBadRequestStatus,
  isUnauthorizedStatus,
  isForbiddenStatus,
  isNotFoundStatus,
  isTimeoutStatus,
  extractDetails,
  isConflictStatus,
  extractResponseStatus,
  extractErrorKey,
};
