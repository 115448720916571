export enum ReceptionProvider {
  ANALOG_CABLE = 'ANALOG_CABLE',
  WEB = 'WEB',
  UNKNOWN = 'UNKNOWN',
  NEW_TO_REQUEST = 'NEW_TO_REQUEST',
  ACTIVE_INQUIRIES = 'ACTIVE_INQUIRIES',
  NOT_ASKED = 'NOT_ASKED',
  DIGITAL_SATELLITE = 'DIGITAL_SATELLITE',
  UNKNOWN_SATELLITE = 'UNKNOWN_SATELLITE',
  UNKNOWN_CABLE = 'UNKNOWN_CABLE',
  DIGITAL_HD_CABLE = 'DIGITAL_HD_CABLE',
  DIGITAL_CABLE = 'DIGITAL_CABLE',
  UNKNOWN_DIGITAL_CABLE = 'UNKNOWN_DIGITAL_CABLE',
  MAGENTA_TV = 'MAGENTA_TV',
  VODAFONE = 'VODAFONE',
  UNKNOWN_IP_TV = 'UNKNOWN_IP_TV',
  DIGITAL_SATELLITE_HD = 'DIGITAL_SATELLITE_HD',
  DVB_T2_HD = 'DVB_T2_HD'
}

export interface AnswersOptions {
  label: string;
  nextQuestion?: Question;
  enumeration?: ReceptionProvider;
  key: string;
}

export interface Question {
  number: number;
  question: string;
  readoutAnswers: AnswersOptions[];
  notReadoutAnswers?: AnswersOptions[];
}


export const readoutAnswersOfQuestionThree: AnswersOptions[] = [
  {
    label: 'reception.survey.question.choices.satellite_digital.hd',
    enumeration: ReceptionProvider.DIGITAL_HD_CABLE,
    key: '7f4de07d-6387-47de-a574-788d807bdd8f',
  },
  {
    label: 'reception.survey.question.choices.satellite_digital.no_hd',
    enumeration: ReceptionProvider.DIGITAL_CABLE,
    key: '88e361ec-1049-4acf-bf11-5c41b4cc1c32',
  },
  {
    label: 'reception.survey.question.choices.unknown',
    enumeration: ReceptionProvider.UNKNOWN_DIGITAL_CABLE,
    key: 'd279f033-5ea7-4b32-91fa-fafd9f09eda1',
  },
];

export const questionThreeOptions: Question = {
  number: 3,
  question: 'reception.survey.question.3',
  readoutAnswers: readoutAnswersOfQuestionThree,
};

export const readoutAnswersOfQuestionTwoCable: AnswersOptions[] = [
  {
    label: 'reception.survey.question.choices.cable.no_analog_cable',
    nextQuestion: questionThreeOptions,
    key: 'e1588a41-fbef-4f05-97ce-1f58f5220b95',
  },
  {
    label: 'reception.survey.question.choices.cable.analog_cable',
    enumeration: ReceptionProvider.ANALOG_CABLE,
    key: '8294d1c6-e792-4010-b1bb-2d5b3abd1386',
  },
  {
    label: 'reception.survey.question.choices.unknown',
    enumeration: ReceptionProvider.UNKNOWN_CABLE,
    key: '81187651-28ae-464b-8a2f-3d4166a386a1',
  },
];

export const readoutAnswersOfQuestionTwoSatelliteDigital: AnswersOptions[] = [
  {
    label: 'reception.survey.question.choices.satellite_digital.hd',
    enumeration: ReceptionProvider.DIGITAL_SATELLITE_HD,
    key: '31d2a096-5979-4c4a-8fd9-9f30cb721057',
  },
  {
    label: 'reception.survey.question.choices.satellite_digital.no_hd',
    enumeration: ReceptionProvider.DIGITAL_SATELLITE,
    key: '90d3b7fe-60a0-4cad-bdb3-e4822ff9ef76',
  },
  {
    label: 'reception.survey.question.choices.unknown',
    enumeration: ReceptionProvider.UNKNOWN_SATELLITE,
    key: 'e69f552a-8052-4983-9931-1250d93d3d46',
  },
];

export const readoutAnswersOfQuestionTwoIpTv: AnswersOptions[] = [
  {
    label: 'reception.survey.question.choices.ip_tv.magenta_tv',
    enumeration: ReceptionProvider.MAGENTA_TV,
    key: 'f3054c6f-418f-4493-869b-5515fae40f35',
  },
  {
    label: 'reception.survey.question.choices.ip_tv.vodafone',
    enumeration: ReceptionProvider.VODAFONE,
    key: '0aa56ae9-82d9-4fb6-bc05-d02bd173c6b3',
  },
  {
    label: 'reception.survey.question.choices.unknown',
    enumeration: ReceptionProvider.UNKNOWN_IP_TV,
    key: '5a0e82f0-4e25-4926-9408-ebffe840f9c3',
  },
];

export const questionTwoSatelliteDigitalOptions: Question = {
  number: 2,
  question: 'reception.survey.question.2.satellite_digital',
  readoutAnswers: readoutAnswersOfQuestionTwoSatelliteDigital,
};

export const questionTwoCableOptions: Question = {
  number: 2,
  question: 'reception.survey.question.2.cable',
  readoutAnswers: readoutAnswersOfQuestionTwoCable,
};

export const questionTwoIpTvOptions: Question = {
  number: 2,
  question: 'reception.survey.question.2.ip_tv',
  readoutAnswers: readoutAnswersOfQuestionTwoIpTv,
};

export const readoutAnswersOfQuestionOne: AnswersOptions[] = [
  {
    label: 'reception.survey.question.choices.satellite_digital',
    nextQuestion: questionTwoSatelliteDigitalOptions,
    key: '249ba17f-ef40-4f0c-be80-5c0739cf7711',
  },
  {
    label: 'reception.survey.question.choices.cable',
    nextQuestion: questionTwoCableOptions,
    key: '1799a3f9-7f2f-4573-a5df-341de69caf93',
  },
  {
    label: 'reception.survey.question.choices.dvb_t2_hd',
    enumeration: ReceptionProvider.DVB_T2_HD,
    key: '104ef84c-5732-4da0-b7f9-ceee6c506590',
  },
  {
    label: 'reception.survey.question.choices.ip_tv',
    nextQuestion: questionTwoIpTvOptions,
    key: '416dc03e-ab7c-4bf4-aaad-c0e2dd057e1c',
  },
];

export const notReadoutAnswersOfQuestionOne: AnswersOptions[] = [
  {
    label: 'reception.survey.question.choices.internet',
    enumeration: ReceptionProvider.WEB,
    key: 'd32735c5-f982-4482-96ba-b1de03ac21c8',
  },
  {
    label: 'reception.survey.question.choices.unknown',
    enumeration: ReceptionProvider.UNKNOWN,
    key: 'a2e387e7-bc4d-44f6-a4ce-92766f20a502',
  },
];

export const questionOneOptions: Question = {
  number: 1,
  question: 'reception.survey.question.1',
  readoutAnswers: readoutAnswersOfQuestionOne,
  notReadoutAnswers: notReadoutAnswersOfQuestionOne,
};
