import { call, put } from 'redux-saga/effects';

import { openModal } from 'src/redux/app/appSlice';
import { setBlockedCustomer } from 'src/redux/customer/customerInfoSlice';
import fetchDuplicatedCustomers from 'src/sagas/customer/fetchDuplicatedCustomers';
import { Customer } from 'src/types/customer/customer';
import { OrderRestriction } from 'src/types/customer/OrderRestriction';
import { Modals } from 'src/types/Modals';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';


export function* handleBlockedCustomer(customer: Customer) {
  let duplicatedCustomers: Customer[] = [];
  const blockedCustomer = reformatBirthdayInResponse(customer);
  if (customer?.orderRestriction === OrderRestriction.duplicate) {
    duplicatedCustomers = yield call(fetchDuplicatedCustomers, blockedCustomer);
  }
  yield put(setBlockedCustomer({ blockedCustomer, duplicatedCustomers }));
  yield put(openModal(Modals.customerBlocked));
}
