import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { Order } from 'src/api/orderTypes';
import { fetchOrderInProgress } from 'src/OrderDisplay/redux/orderDisplaySlice';
import { showInfoNotification } from 'src/redux/app/appSlice';
import { setGlobalLoadingFlag } from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import {
  fetchOriginalOrderSuccess,
  checkAndFetchOriginalOrder,
  loadOriginalOrder,
  loadOriginalOrderSuccess,
} from 'src/redux/recreateOrder/recreateOrderSlice';
import { handleLoadOriginalOrderFailureState } from 'src/sagas/recreateOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';


export function* checkAndFetchOriginalOrderSaga(action: PayloadAction<string | null>) {
  const orderId = action.payload;
  try {
    const trimmedOrderId = orderId?.trim().replace(/ /g, '');
    if (trimmedOrderId) {
      const isRekla = yield select(orderEntrySelector.isReklaOrder);
      yield put(setGlobalLoadingFlag(true));
      const apiOrder = yield getContext(SagaContextItem.apiOrder);
      yield put(fetchOrderInProgress());
      const orderResponse: Order = yield call(apiOrder.getOrder, trimmedOrderId);
      
      if (isRekla && (orderResponse.items.length !== 1 || !!orderResponse.items.find(it => it.quantity > 1))) {
        yield put(showInfoNotification('reklaOrder.claimOrderNotPossible'));
        yield put(setGlobalLoadingFlag(false));
        return ;
      }
      
      yield put(fetchOriginalOrderSuccess(orderResponse));
      yield put(loadOriginalOrder(orderResponse));
    } else {
      yield put(loadOriginalOrderSuccess());
    }
  } catch (err) {
    yield call(handleLoadOriginalOrderFailureState, err, orderId);
    yield put(setGlobalLoadingFlag(false));
  }
}

export default function* fetchOriginalOrderWatcher() {
  yield takeLatest(checkAndFetchOriginalOrder.type, checkAndFetchOriginalOrderSaga);
}
