import { CountryCode } from 'src/api/orderTypes';
import { ItemSource } from 'src/types/offer/ItemSource';
import { ListType } from 'src/types/offer/ListType';
import { CrossSellInfo } from 'src/types/product/CrossSell';
import { Currency } from 'src/types/product/Currency';
import { ShippingType } from 'src/types/product/DeliveryTime';
import {
  DimensionType,
  ProductDimension,
  VariantDimension,
} from 'src/types/product/dimensions';
import { ProductPrice } from 'src/types/product/prices';
import { SelectedVariant } from 'src/types/product/SelectedVariant';
import { productSubscriptionsNumbers } from 'src/utils/product/productUtils';


export interface ProductSearchResponse {
  totalProducts: number;
  products: SelectedVariant[];
}

export interface Product {
  id: string;
  masterValues: MasterVariant;
  variantValues: Variant[];
  isRemindable?: boolean;
}

export interface MasterVariant {
  brand: string;
  additionalInformation: string;
  categoryPath: string[][];
  masterProductNumber: string;
  freeShipping: boolean;
  description: string;
  categoryUrl: string[][];
  salesdrivers: string[];
  brandUrl: string;
  name: string;
  shortDescription: string;
  longName?: string;
  brandName?: string;
}

export interface Variant {
  basePrice: number;
  basePriceAmount: number;
  basePriceUnit: string;
  imageURL?: string;
  price: number;
  priceLabel: string;
  productNumber: string;
  referencePrice: number;
  referencePriceLabel: string;
  referencePriceSaving: number;
  referencePriceSavingPercent: number;
  referencePriceType: string;
  status: ProductStatus;
  colorFamilies: string[];
  sIZE: string[];
  cOLOR: string[];
  tASTE?: string[];
  aLLOY?: string[];
  mOTIV?: string[];
  aROMA?: string[];
  aMOUNT?: string[];
  aTONE?: string[];
}

export interface SearchParams {
  query?: string;
  filters?: SearchFilter[];
}

export interface SearchFilter {
  name:
    | 'Name'
    | 'MasterProductNumber'
    | 'ProductNumber'
    | 'Description'
    | 'CategoryUrl'
    | 'FreeShipping'
    | 'CategoryPath'
    | 'Brand';
  value: string;
  substring?: boolean;
}

export interface ProductDetails {
  baseProductNo: string;
  country?: CountryCode;
  name: ProductName;
  description?: string;
  additionalInformation?: string;
  hazardWarning?: string;
  ingredients?: string;
  headline?: string;
  media?: Media[];
  usps?: string[];
  energyEfficiency?: EnergyEfficiency;
  salesdrivers?: Salesdriver[];
  legalInformation?: Media;
  dimensions?: ProductDimension[];
  imageRelevantDimension?: DimensionType;
  showStartingPrice?: boolean;
  defaultVariantSku: string;
  variants: VariantValue[];
  brand?: ProductBrand;
  status: ProductStatus;
  financing?: Financing;
  seo?: ProductSeo;
  deliveryInfo?: string | null;
  categoryPath?: string;
  created?: string;
  newInShop?: string;
  freeShipping?: boolean;
  itemSource?: ItemSource;
  isRemindable?: boolean;
  tvOrTopDealInfo?: string;
  crossSell?: CrossSellInfo;
  countryVisibilities?: CountryCode[];
  tag?: string;
  priceDate?: Date;
  subscriptionInfo?: ProductSubscriptionInfo;
  freeShippingCampaigns?: FreeShippingCampaigns[];
}

export interface ProductSubscriptionInfo {
  deliveryProductNo: string;
  maintenanceStatus: ProductSubscriptionStatus;
}

export enum ProductSubscriptionStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  RAN_OUT = 'RAN_OUT',
  RUNNING_OUT = 'RUNNING_OUT',
  REPLACED = 'REPLACED',
  STOPPED = 'STOPPED'
}

export interface VariantValue {
  sku: string;
  dimensions?: VariantDimension[];
  status?: ProductStatus;
  price: ProductPrice;
  deliveryTime?: DeliveryTime;
  images?: Media[];
  erpStatus?: ErpProductStatus;
  isSellable?: boolean;
  erpStatusTv?: ErpProductStatus;
  linkedSubscriptionProduct?: ProductDetails;
}

export enum ErpProductStatus {
  SELLABLE = 'SELLABLE',
  NOT_IN_SORTIMENT = 'NOT_IN_SORTIMENT',
  NOT_RELEASED = 'NOT_RELEASED'
}

export interface ProductSeo {
  description?: string;
  title?: string;
}

export interface ProductName {
  short?: string;
  long: string;
}

export interface ProductBrand {
  brandName?: string;
  productLineName?: string;
  brandIcon?: Media;
}

export interface Media {
  uri: string;
  mediaType: string;
}

export interface ProductStock {
  sku: string;
  amount: number;
  isRemindable?: boolean; // TODO: remove this isRemindable field
  stockReminder?: boolean;
}

export interface EnergyEfficiency {
  class?: string;
  colorCode?: string;
  label?: Media;
  infoSheet?: Media;
}

export interface Salesdriver {
  typeCode: string;
  name: string;
  validFrom?: string;
  validTo?: string;
}

export interface DeliveryTime {
  deliveryDays?: number;
  deliveryDate?: string;
  deliveryMinDays?: number;
  shippingType?: ShippingType;
  deliveryMessage?: String;
  deliveryType?: DeliveryType;
}

export interface Shipping {
  freeShipping?: boolean;
}

export interface Financing {
  zeroPercent?: boolean;
}

export enum ProductStatus {
  Sellable = 'SELLABLE',
  Waitlistsellable = 'WAITLISTSELLABLE',
  Soldout = 'SOLDOUT',
  SubscriptionSellable = 'SUBSCRIPTION_SELLABLE',
  Archived = 'ARCHIVED',
  Unprepared = 'UNPREPARED',
  Prepared = 'PREPARED',
  Discarded = 'DISCARDED',
}

export enum DeliveryType {
  'TELEPHONE_NOTIFICATION' = 'TELEPHONE_NOTIFICATION',
  'THIRD_PARTY_DELIVERY' = 'THIRD_PARTY_DELIVERY',
  'CONSIGNMENT' = 'CONSIGNMENT',
}

export interface OfferProductInterface {
  tag?: string;
  baseProductNo: string;
  brand: string;
  name: string;
  imageUri?: string;
  price: {
    reference: { savingPercent: number; value: number };
    currencyCode: Currency;
    value: number;
  };
  freeShipping: boolean;
}

export interface ChannelProducts {
  tvChannel: string;
  productNos: string[];
}

export interface StocksRemindableResponse {
  baseProductsNumbers: string[];
}

export interface ProductImpressionsViewItem {
  name: string;
  id: string;
  price: number;
  brand: string;
  category: string;
  variant: string;
  list?: string;
  position: number;
  dimension10: string;
  dimension2: string;
  dimension8: string;
  dimension9: string;
  dimension12: string;
}
export interface ProductImpressionsView {
  impressions: ProductImpressionsViewItem[];
}

export interface ProductDetailsViews {
  detail: ProductDetailsViewsClick;
}

export interface ProductDetailsViewsClick {
  actionField: ProductDetailsViewsClickList;
  products: ProductDetailsViewsItem[] | undefined;
}

export interface ProductDetailsViewsClickList {
  list?: string;
  action: string;
}

export interface ProductDetailsViewsItem {
  name: string;
  id: string;
  price: string;
  brand?: string;
  category?: string;
  variant?: string;
  dimension10: string;
  dimension2?: string;
  dimension8?: Salesdriver[] | string;
  dimension9?: string;
  dimension12: string;
}

export interface ProductDetailsViewsSource {
  listType: ListType;
  listName?: string;
}

export interface UpsellType {
  sku: string;
  name?: string;
}

export interface FreeShippingCampaigns {
  campaignId?: string;
  voucherCode?: string;
  campaignType: string;
  activeGlobalCampaign: boolean;
  freeShipping: boolean;
  minOrderValue?: number;
  salesChannels?: CampaignSalesChannel[];
}

export enum CampaignSalesChannel {
  CLASSIC_TV = 'CLASSIC_TV',
  CLASSIC_APP = 'CLASSIC_APP',
  CLASSIC_SHOP = 'CLASSIC_SHOP',
  HELLO_APP = 'HELLO_APP'
}

export function isSubscriptionProduct(product: ProductDetails) {
  return product.status === ProductStatus.SubscriptionSellable
    || productSubscriptionsNumbers.find(bpno => bpno === product.baseProductNo) !== undefined; // hardcoded product because of wrong data from source
}
