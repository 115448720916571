export default {
  'orderHistory.from': 'von',
  'orderHistory.to': 'bis',
  'orderHistory.apply': 'Anwenden',
  'orderHistory.cancel': 'Abbrechen',

  'orderHistory.last14Days': '2 Wochen',
  'orderHistory.lastMonth': 'Diesen Monat',
  'orderHistory.lastYear': 'Dieses Jahr',
  'orderHistory.customRange': 'Bereich auswählen',
  'orderHistory.orderNumber': 'Auftragsnummer',
  'orderHistory.orderDate': 'Bestelldatum',
  'orderHistory.deliveryStatus': 'Lieferstatus',
  'orderHistory.source': 'Bestellkanal',
  'orderHistory.paymentMethod': 'Zahlungsart',
  'orderHistory.shippingDelay': 'Lieferzeit',
  'orderHistory.totalPrice': 'Gesamtbetrag',
  'orderHistory.freeshipping': 'Kostenloser Versand',
  'orderHistory.paymentMethod.PREPAYMENT': 'Vorauszahlung',
  'orderHistory.paymentMethod.PAYPAL': 'Paypal',
  'orderHistory.paymentMethod.DIRECT_DEBIT': 'Lastschrift',
  'orderHistory.paymentMethod.INVOICE': 'Rechnung',
  'orderHistory.paymentMethod.CASH_ON_DELIVERY': 'Nachnahme',
  'orderHistory.paymentMethod.CONSUMER_CREDIT': 'Verbraucherkredit',
  'orderHistory.paymentMethod.CREDIT_CARD': 'Kreditkarte',
  'orderHistory.paymentMethod.PARTIAL_PAYMENT': 'Teilzahlung',
  'orderHistory.paymentMethod.UNKNOWN': 'Unbekannt',
  'orderHistory.title': 'Bestellhistorie',
  'orderHistory.summary.subtotal': 'Zwischensumme',
  'orderHistory.summary.total': 'Gesamtsumme',
  'orderHistory.summary.saving': 'Ersparnis',
  'orderHistory.summary.shipping': 'Versand',
  'orderHistory.summary.voucher': 'Gutschein',
  'orderHistory.openButton' : 'Bestellhistorie',
  'orderHistory.feedback.emptyResult' :  'Es sind noch keine Bestellungen vorhanden',
  'orderHistory.billNumber' :  'Rechnungsnummer',
  'orderHistory.noBillNumber' :  'Noch nicht erstellt',
  'orderHistory.billingAddress' :  'Rechnungsadresse',
  'orderHistory.deliveryAddress' :  'Lieferadresse (Postanschrift)',
  'orderHistory.shippingAddress' :  'Lieferadresse',
  'orderHistory.orderInformations' : 'Bestellinformationen',
  'orderHistory.cancelOrder_modalTitle': 'Bestellung stornieren',
  'orderHistory.cancelOrder_modalConfirme': 'Ja, stornieren',
  'orderHistory.cancelOrder_modalDecline': 'Nein',
  'orderHistory.cancelOrder_modalMessage': 'Bitte bestätigen Sie die Stornierung der Bestellung!',
  'orderHistory.cancelOrder.txtBtn': 'Stornieren',
  'orderHistory.NoMoreResults': 'Keine Ergebnisse mehr.',

  'orderHistory.back': 'Zurück zur Bestellseite',
  'orderHistory.moreInfo': 'Mehr infos',
  'orderHistory.result': 'Bestellhistorie',
  'orderHistory.noResult': 'Leider ist derzeit keine Bestellung in {status}',
  'orderHistory.tabs.allOrders': 'Alle Bestellungen',
  'orderHistory.tabs.inProgress': 'In Bearbeitung',
  'orderHistory.tabs.closed': 'Abgeschlossen',
  'orderHistory.searchOrder': 'Nach BNR, Produkt, etc. suchen',
  'orderHistory.billingInfo': 'Rechnungsinfo',
  'orderHistory.orderInfo': 'Bestellinformationen',
  'orderHistory.deliveryInfo': 'Lieferinfo',
  'orderHistory.multipleDeliveryPosition': 'Lieferadressen stehen in den Positionen',
  'orderHistory.paymentInformation': 'Zahlungsinfo',
  'orderHistory.positions': 'Positionshistorie',
  'orderHistory.priceDetails': 'Details Gesamtbetrag',
  'orderHistory.addToBasket': 'Erneut kaufen',
  'orderHistory.reklaButton': 'Rekla-Auftrag',
  'orderHistory.noTrackingNumber': 'Sendungsnummer ist noch nicht verfügbar',
  'orderHistory.trackingNumber': 'Sendungsnummer',
  'orderHistory.package': 'Paket',
  'orderHistory.carrier': 'Spedition',
  'orderHistory.waitlistEntryDate': 'Wartelisteneintrag vom {date}',

  'orderHistory.postitionCreatedAt': 'Angelegt am: {date} Uhr',

  'orderHistory.customerNr': 'Kundennummer',
  'orderHistory.email': 'E-Mail-Adresse',
  'orderHistory.salutation': 'Anrede',
  'orderHistory.firstName': 'Vorname',
  'orderHistory.lastName': 'Nachname',
  'orderHistory.dateOfBirth': 'Geburtsdatum',
  'orderHistory.phone': 'Telefonnummer',
  'orderHistory.createdBy': 'Erstellt von',
  'orderHistory.response': 'Eingangskanal',
  'orderHistory.salutation.MR': 'Herr',
  'orderHistory.salutation.MS': 'Frau',
  'orderHistory.edit.back': 'Zurück zur Bestellhistorie',
  'orderHistory.orderedOn': 'Bestellt am {date}',
  'orderHistory.changesNotSaved': 'Die Änderungen, die Sie vorgenommen haben, werden nicht gespeichert.',
  'orderHistory.editOrder.txtBtn': 'Bearbeiten',
  'orderHistory.deliveryDays': 'Liefertage',
  'orderHistory.days': 'Tage',
  'orderHistory.info': 'Auftragsinfo',

  'orderHistory.brochureHint': 'Broschüre "Willkommen bei HSE24" wurde mit dieser Bestellung versendet.',
  'orderHistory.filters.clearAll': 'Alle Filter löschen',
  'orderHistory.salesOffice': '/  Fenster: {salesOffice}',

  'orderHistory.item.rekla.hint': 'Ersatz für Auftragsnummer:',
  'orderHistory.item.rekla.hint.withoutOrderNumber': 'ohne Bezug',
  'orderHistory.item.rekla.hint.onOriginalOrder': 'Ersatz für diesen Artikel wurde gesendet. Auftragsnummer:',

  'orderHistory.voucher.title': '{value}{currency}- Gutschein "{code}" wird eingelöst',
  'orderHistory.createdAt': 'Angelegt am ',
  'orderHistory.userAgentName': 'Angelegt von ',
  'orderHistory.creationInfo': 'Erstellungsinfo',
};
