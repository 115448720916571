import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal } from 'src/redux/app/appSlice';
import {
  createCustomer,
  CheckCustomerByNameAndBirthday,
  checkCustomerByNameAndBirthday
} from 'src/redux/customer/customerInfoSlice';
import {
  cleanCustomerSuggestion,
  fetchCustomerByNameAndBirthdaySuccess,
  setCustomerCreateFormValues,
} from 'src/redux/customer/customerSuggestionsSlice';
import { customerSuggestionsSelector } from 'src/redux/customer/selectors/customerSuggestionsSelectors';
import fetchDuplicatedCustomers from 'src/sagas/customer/fetchDuplicatedCustomers';
import { Customer } from 'src/types/customer/customer';
import { OrderRestriction } from 'src/types/customer/OrderRestriction';
import { Modals } from 'src/types/Modals';
import { isNotFoundStatus } from 'src/utils/errorStatusChecks';


export function* checkCustomerByNameAndBirthdaySaga(action: CheckCustomerByNameAndBirthday) {
  yield call(checkCustomer, action.payload);
}

export function* checkCustomer(payload: Customer) {
  try {
    const criteria = { firstName: payload.firstName, lastName: payload.lastName, dateOfBirth: payload.dateOfBirth };
    const response: Customer[] = yield call(fetchDuplicatedCustomers, criteria);
    if (response.length <= 0) {
      return;
    }
    yield put(fetchCustomerByNameAndBirthdaySuccess(response));
    yield put(setCustomerCreateFormValues(payload));
    if(response && response.length === 1 && response[0].indicators?.orderRestriction === OrderRestriction.deletionGdpr){
      const formData = yield select(customerSuggestionsSelector.getFormCustomer)!;
      const customerCreateData = { ...formData, skipDoubleCheck: true } as Customer;
      yield put(createCustomer(customerCreateData));
      yield put(cleanCustomerSuggestion());
    } else {
      yield put(openModal(Modals.duplicatedCustomer));
    }
  } catch (err) {
    if(!isNotFoundStatus(err)) {
      yield put(logErrorEvent({ message: 'Could not check customer', err }));
    }
  }
}

export function* checkCustomerByNameAndBirthdayWatcher() {
  yield takeLatest(checkCustomerByNameAndBirthday.type, checkCustomerByNameAndBirthdaySaga);
}
