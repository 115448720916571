import dayjs from 'dayjs';
import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { DEFAULT_SALES_CHANNEL, DEFAULT_SALES_OFFICE } from 'src/config/salesChannels';
import { DEFAULT_DISPLAY_DATE_FORMAT } from 'src/constants/date';
import { openModal, showErrorNotification } from 'src/redux/app/appSlice';
import { resetPostCustomerAuthCheckData } from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { fetchSubscriptionHistory } from 'src/redux/customer/subscriptionHistorySlice';
import { initializeCreateSubscription } from 'src/redux/product/createSubscriptionSlice';
import {
  fetchProduct,
  FetchProduct,
  fetchProductError,
  fetchProductSuccess,
  setSelectedVariant,
} from 'src/redux/product/productDetailsSlice';
import { fetchProductStock } from 'src/redux/product/productStockSlice';
import getProductBySku from 'src/sagas/product/productService';
import { logRequestTime } from 'src/sagas/utils/temporaryRequestLogger';
import { Modals } from 'src/types/Modals';
import { ItemSource } from 'src/types/offer/ItemSource';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { ProductDetails, VariantValue } from 'src/types/product/product';
import { SubscriptionStartIn } from 'src/types/subscription/SubscriptionRequest';
import { FetchSubscriptionsKeywordCriteriaType, SubscriptionStatusRequest } from 'src/types/subscription/SubscriptionsOverviewRequest';
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';


export function* onFetchProductDetailsSaga({ payload: { sku, itemSource, tvOrTopDealInfo, crossSell } }: FetchProduct) {
  const startTime = new Date();
  try {
    const baseProductNumber = toBaseProductNumber(sku);
    const product: ProductDetails = yield call(getProductBySku, sku);

    yield put(fetchProductSuccess({
      ...product,
      itemSource,
      tvOrTopDealInfo,
      crossSell,
    }));
    const baseProductNos = [baseProductNumber];
    if(product.subscriptionInfo?.deliveryProductNo) {
      baseProductNos.push(product.subscriptionInfo.deliveryProductNo);
      const externalCustomerId: string | undefined = yield select(customerInfoSelectors.getCustomerExternalId);
      if(externalCustomerId){
        yield put(fetchSubscriptionHistory({
          externalCustomerId,
          criteria: {
            keyword: product.baseProductNo,
            status: SubscriptionStatusRequest.ACTIVE,
            limit: 1,
            keywordType: FetchSubscriptionsKeywordCriteriaType.SUBSCRIPTION_PRODUCT_NO,
          } }));
        yield put(resetPostCustomerAuthCheckData());
      }
    }
    const linkedSubscriptionProduct = product.variants?.[0]?.linkedSubscriptionProduct;

    if(linkedSubscriptionProduct) {
      baseProductNos.push(linkedSubscriptionProduct.baseProductNo);
      if(linkedSubscriptionProduct.subscriptionInfo) {
        baseProductNos.push(linkedSubscriptionProduct.subscriptionInfo.deliveryProductNo);
      }
    }

    yield put(fetchProductStock(baseProductNos));
    yield put(setSelectedVariant(product.variants?.find((v: VariantValue) => v.sku === sku)));
    yield put(openModal(Modals.productDetails));
    if(itemSource === ItemSource.SUBSCRIPTION_OFFER && product.variants.length < 2 && linkedSubscriptionProduct){
      yield put(initializeCreateSubscription({
        product: linkedSubscriptionProduct,
        sku,
        shippingCosts: 0,
        totalPrice: 0,
        quantity: 1,
        rotation: undefined,
        startIn: SubscriptionStartIn.TODAY,
        nextOrderDate: dayjs().format(DEFAULT_DISPLAY_DATE_FORMAT),
        sourceChannel: SourceChannels.TELE,
        salesOffice: DEFAULT_SALES_OFFICE,
        salesChannel: DEFAULT_SALES_CHANNEL,
      }));
    }
  } catch (err) {
    const errorCode = err?.response?.status || 400;
    yield put(fetchProductError(errorCode));
    yield put(showErrorNotification());
  } finally {
    yield call(logRequestTime, 'Fetch product detail', startTime);
  }
}

export default function* onFetchProductDetailsWatcher() {
  yield takeEvery(fetchProduct.type, onFetchProductDetailsSaga);
}
