import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';

import { FilterType } from '../types/SubscriptionProductLinkRequest';


export const subscriptionLinksManagementSelectors = createSelectors({
  getOverview(state: RootStateType) {
    return state.subscriptionLinksManagement.overview.items;
  },
  getCreateSubscriptionLinkLoading(state: RootStateType) {
    return state.subscriptionLinksManagement.createLink.loading;
  },
  getEditSubscriptionLinkState(state: RootStateType) {
    return state.subscriptionLinksManagement.editLink;
  },
  getSearchByBnrState(state: RootStateType) {
    return state.subscriptionLinksManagement.bnrSearch;
  },
  openModals(state: RootStateType) {
    return state.subscriptionLinksManagement.modals;
  },
  getFilters(state: RootStateType) {
    return state.subscriptionLinksManagement.overview.filters;
  },
  getActiveDisplayedFilter(state: RootStateType) {
    return state.subscriptionLinksManagement.overview.filters.displayedFilter;
  },
  getFilterConstraint(state: RootStateType) {
    return state.subscriptionLinksManagement.overview.filters.activeFilters.find(filter => filter.type === state.subscriptionLinksManagement.overview.filters.displayedFilter);
  },
  getKeywordFilter(state: RootStateType) {
    return state.subscriptionLinksManagement.overview.filters.activeFilters.find(filter => filter.type === FilterType.SEARCH_BY_KEYWORD);
  },
  getOverviewState(state: RootStateType) {
    return state.subscriptionLinksManagement.overview;
  },
});
