import { CountryCode } from 'src/api/orderTypes';
import { BillingAddress } from 'src/types/customer/address';
import { AddressValidationRequest } from 'src/types/customer/AddressValidationRequest';
import { Salutation } from 'src/types/customer/Salutation';


const serializeAddress = (address: BillingAddress): AddressValidationRequest => {
  return buildAddressValidationRequest(
    address.countryCode,
    address.street,
    address.streetNumber.toString(),
    address.zipCode.toString(),
    address.city,
  );
};

export const buildAddressValidationRequest = (
  countryCode: CountryCode,
  street: string,
  streetNumber: string,
  zipCode: string,
  city?: string,
): AddressValidationRequest => {
  return {
    salutation: Salutation.mister,
    firstName: '',
    lastName: '',
    street,
    streetNumber,
    zipCode,
    city: city || '',
    countryCode,
  };
};

export default serializeAddress;
