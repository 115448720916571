import {
  call,
  getContext,
  put
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logErrorEvent } from 'src/logging/loggingActions';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Customer } from 'src/types/customer/customer';
import { isNotFoundStatus } from 'src/utils/errorStatusChecks';
import formatBirthdayToServicesFormat from 'src/utils/formatters/formatBirthdayToServicesFormat';
import { validateEmail } from 'src/utils/validators/validation';


function* fetchDuplicatedCustomers(criteria: FetchDuplicateCustomersCriteria) {
  const customers = [];
  try {
    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    const { email, lastName, firstName } = criteria;
    if (email && validateEmail(email)) {
      try {
        const customerByEmail: Customer = yield call(apiCustomer.fetchCustomerByEmail, email);
        if (customerByEmail) {
          customers.push(customerByEmail);
        }
      } catch (err) {
        if (!isNotFoundStatus(err)) {
          yield put(logErrorEvent({ message: 'Could not fetch customer by email', err }));
        }
      }
    }

    const dateOfBirth = formatBirthdayToServicesFormat(criteria.dateOfBirth);
    if (firstName && lastName && dateOfBirth) {
      try {
        const customersByNameAndDateOfBirth: Customer[] = yield call(
          apiCustomer.fetchCustomersByNameAndDateOfBirth,
          { firstName, lastName, dateOfBirth },
        );
        if (customersByNameAndDateOfBirth) {
          customers.push(...customersByNameAndDateOfBirth);
        }
      } catch (err) {
        if (!isNotFoundStatus(err)) {
          yield put(logErrorEvent({ message: 'Could not fetch duplicate customer by firstname, lastname, dateOfBirth', err }));
        }
      }
    }
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not fetch duplicate customer', err }));
  }
  return customers;
}

interface FetchDuplicateCustomersCriteria {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  email?: string;
}

export default fetchDuplicatedCustomers;
