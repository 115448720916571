import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { logErrorEvent } from 'src/logging/loggingActions';
import {
  closeModal,
  openModal,
  showErrorNotificationWithBackendMessage,
  showSuccessNotification,
} from 'src/redux/app/appSlice';
import {
  cleanEditedSubscriptionState,
  SubmitEditedSubscriptionAction,
  submitEditedSubscriptionAction,
  editSubscriptionHistoryItemFailed,
} from 'src/redux/customer/editSubscriptionSlice';
import { editSubscriptionHistoryItemSuccess } from 'src/redux/customer/subscriptionHistorySlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { editSubscriptionSuccess } from 'src/subscriptionProductManagement/redux/subscriptionManagementSlice';
import { Modals } from 'src/types/Modals';
import { EditSubscriptionRequest } from 'src/types/subscription/EditSubscriptionRequest';
import { extractDetails, extractErrorKey } from 'src/utils/errorStatusChecks';


export function* submitEditedSubscriptionSaga(action: SubmitEditedSubscriptionAction) {
  const editedSubscription = action.payload;
  const body: EditSubscriptionRequest = {
    deliveryAddress: editedSubscription.details.deliveryAddress,
    nextOrderDate: editedSubscription.aboInfo.nextOrderDate,
    payment: editedSubscription.details.payment,
    rotation: editedSubscription.aboInfo.rotation,
    deliveryProductQuantity: editedSubscription.details.product.quantity,
  };
  try {
    const apiCallCenter: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);
    yield call(apiCallCenter.editSubscription, editedSubscription.id, body);
    yield put(closeModal(Modals.EditSubscriptionModal));
    yield put(openModal(Modals.EditSubscriptionConfirmation));
    yield put(cleanEditedSubscriptionState());
    yield put(editSubscriptionHistoryItemSuccess(editedSubscription));
    yield put(editSubscriptionSuccess(editedSubscription));
    yield put(showSuccessNotification('subscription.edit.successNotificationHint'));
  } catch (err) {
    if(extractErrorKey(err) !== '/dpl/ccs/errors/next_order_date_already_processed') {
      yield put(logErrorEvent({ message: `Couldn't edit subscription with id ${editedSubscription.id}, and body: ${JSON.stringify(body)}` }));
    }
    yield put(showErrorNotificationWithBackendMessage(extractDetails(err) ?? 'Hoppla... Fehler beim Bearbeiten des Abonnements'));
    yield put(editSubscriptionHistoryItemFailed());
  }
}

export default function* submitEditedSubscriptionWatcher() {
  yield takeLatest(submitEditedSubscriptionAction.type, submitEditedSubscriptionSaga);
}
