import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import {
  closeModal,
  showErrorNotificationWithBackendMessage,
  showSuccessNotification,
} from 'src/redux/app/appSlice';
import {
  cleanEditedSubscriptionState,
  editSubscriptionHistoryItemFailed,
  submitEditedSubscriptionNumberAction,
  SubmitEditedSubscriptionNumberAction,
} from 'src/redux/customer/editSubscriptionSlice';
import { editSubscriptionHistoryItemSuccess } from 'src/redux/customer/subscriptionHistorySlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { editSubscriptionSuccess } from 'src/subscriptionProductManagement/redux/subscriptionManagementSlice';
import { Modals } from 'src/types/Modals';
import { EditSubscriptionRequest } from 'src/types/subscription/EditSubscriptionRequest';
import { extractDetails } from 'src/utils/errorStatusChecks';


export function* submitEditedSubscriptionNumberSaga(action: SubmitEditedSubscriptionNumberAction) {
  const editedSubscription = action.payload;
  const body: EditSubscriptionRequest = {
    deliveryProductNumber: editedSubscription.details.product.sku,
    subscriptionProductNumber: editedSubscription.aboInfo.aboNumber
  };
  try {
    const apiCallCenter: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);
    yield call(apiCallCenter.editSubscription, editedSubscription.id, body);
    yield put(closeModal(Modals.EditSubscriptionProductNumberModal));
    yield put(cleanEditedSubscriptionState());
    yield put(editSubscriptionHistoryItemSuccess(editedSubscription));
    yield put(editSubscriptionSuccess(editedSubscription));
    yield put(showSuccessNotification('subscription.edit.successNotificationHint'));
  } catch (err) {
    yield put(showErrorNotificationWithBackendMessage(extractDetails(err) ?? 'Hoppla... Fehler beim Bearbeiten des Abonnements'));
    yield put(editSubscriptionHistoryItemFailed());
  }
}

export default function* submitEditedSubscriptionNumberWatcher() {
  yield takeLatest(submitEditedSubscriptionNumberAction.type, submitEditedSubscriptionNumberSaga);
}
