import {
  connectRouter,
  LocationChangeAction,
  RouterState,
} from 'connected-react-router';
import { History } from 'history';
import {
  AnyAction,
  combineReducers,
  Reducer,
} from 'redux';

import { chOrderEntryReducer, initialCHOrderEntryState } from 'src/CHOrderEntry/redux/chOrderEntrySlice';
import {
  BotState,
  CustomerState,
  OrderState,
  ProductState,
  RootStateType,
} from 'src/constants/types';
import { editedOrderReducer } from 'src/editedorder/reducers/EditedOrderReducers';
import { initialEditedOrderState } from 'src/editedorder/types/EditedOrderState';
import orderDisplayReducer, { orderDisplayInitialState } from 'src/OrderDisplay/redux/orderDisplaySlice';
import { orderPreCheckInitialState, orderPreCheckReducer } from 'src/orderPreCheck/redux/orderPreCheckSlice';
import botRequestReducer, { botRequestInitialState } from 'src/redux/bot/botRequestSlice';
import customerPinReducer, { customerPinInitialState } from 'src/redux/customer/CustomerPinSlice';
import customerSuggestionReducer, { customerSuggestionsInitialState } from 'src/redux/customer/customerSuggestionsSlice';
import editOrderSlice, { editOrderInitialState } from 'src/redux/editOrder/editOrderSlice';
import inflightOrderReducer, { inflightOrderInitialState } from 'src/redux/inflightOrder/inflightOrderSlice';
import createWaitlistItemReducer, { createWaitlistItemInitialState } from 'src/redux/product/createWaitlistItemSlice';
import productDetailsReducer, { productDetailsInitialState } from 'src/redux/product/productDetailsSlice';
import productSearchReducer, { productSearchInitialState } from 'src/redux/product/productSearchSlice';
import productStockReducer, { productStockInitialState } from 'src/redux/product/productStockSlice';
import productSuggestionsReducer, { productSuggestionsInitialState } from 'src/redux/product/productSuggestionsSlice';
import productUpsellsReducer, { productUpsellsInitialState } from 'src/redux/product/productUpsellsSlice';
import stockReminderReducer, { stockReminderInitialState } from 'src/redux/product/stockReminderSlice';
import recreateOrderReducer, { recreateOrderInitialState } from 'src/redux/recreateOrder/recreateOrderSlice';
import newCustomerSlice, { newCustomerStateInitialState } from 'src/sagas/newCustomer/newCustomerSlice';
import { initialSubscriptionLinksManagementState, subscriptionLinksManagementReducer } from 'src/subscriptionLinksManagement/redux/subscriptionLinksManagementSlice';
import { subscriptionManagementInitialState, subscriptionManagementReducer } from 'src/subscriptionProductManagement/redux/subscriptionManagementSlice';
import { subscriptionReportInitialState, subscriptionReportReducer } from 'src/subscriptionProductManagement/redux/subscriptionReportSlice';

import appSlice, { appInitialState } from './app/appSlice';
import { notificationsTickerInitialState, notificationsTickerSlice } from './app/notificationsTickerSlice';
import addressSuggestionsSlice, { addressSuggestionsInitialState } from './customer/addressSuggestionsSlice';
import customerInfoSlice, { customerInfoInitialState } from './customer/customerInfoSlice';
import customerSearchReducer, { customerSearchInitialState } from './customer/customerSearchSlice';
import editSubscriptionReducer, { editSubscriptionInitialState } from './customer/editSubscriptionSlice';
import orderHistoryReducer, { orderHistoryInitialState } from './customer/orderHistorySlice';
import subscriptionHistoryReducer, { subscriptionHistoryInitialState } from './customer/subscriptionHistorySlice';
import waitlistHistoryReducer, { waitlistHistoryInitialState } from './customer/waitlistHistorySlice';
import offerReducer from './offers/offerReducer';
import { offersInitialState } from './offers/offersState';
import newOrderReducer, { newOrderInitialState } from './order/newOrderSlice';
import orderEntryReducer, { orderEntryInitialState } from './order/orderEntrySlice';
import salesChannelReducer, { salesChannelInitialState } from './order/salesChannelSlice';
import salesOfficeReducer, { salesOfficeInitialState } from './order/salesOfficeSlice';
import sourceChannelReducer, { sourceChannelInitialState } from './order/sourceChannelSlice';
import voucherReducer, { voucherInitialState } from './order/voucherSlice';
import paymentReducer, { paymentInitialState } from './payment/paymentSlice';
import createSubscriptionReducer, { createSubscriptionInitialState } from './product/createSubscriptionSlice';
import serviceVoucherReducer from './serviceVoucher/serviceVoucherReducer';
import { serviceVoucherInitialState } from './serviceVoucher/serviceVoucherState';
import { previewLetterReducer, previewLetterStateInitialState } from '../previewLetter/redux/proviewLetterSlice';


export const reduxInitialState: RootStateType = {
  app: appInitialState,
  product: {
    details: productDetailsInitialState,
    search: productSearchInitialState,
    suggestions: productSuggestionsInitialState,
    stocks: productStockInitialState,
    upsells: productUpsellsInitialState,
    stockReminder: stockReminderInitialState,
    createWaitlistItem: createWaitlistItemInitialState,
    createSubscription: createSubscriptionInitialState,
  },
  customer: {
    info: customerInfoInitialState,
    search: customerSearchInitialState,
    orderHistory: orderHistoryInitialState,
    addressSuggestions: addressSuggestionsInitialState,
    suggestions: customerSuggestionsInitialState,
    waitlistHistory: waitlistHistoryInitialState,
    subscriptionHistory: subscriptionHistoryInitialState,
    editSubscription: editSubscriptionInitialState,
    pinVerification: customerPinInitialState,
    newCustomer: newCustomerStateInitialState,
  },
  order: {
    orderDisplay: orderDisplayInitialState,
    newOrder: newOrderInitialState,
    orderEntry: orderEntryInitialState,
    voucher: voucherInitialState,
    salesOffice: salesOfficeInitialState,
    salesChannel: salesChannelInitialState,
    sourceChannel: sourceChannelInitialState,
  },
  payment: paymentInitialState,
  serviceVoucher: serviceVoucherInitialState,
  offers: offersInitialState,
  notifications: notificationsTickerInitialState,
  editOrder: editOrderInitialState,
  inflightOrder: inflightOrderInitialState,
  editedOrder: initialEditedOrderState,
  recreateOrder: recreateOrderInitialState,
  subscriptionManagement: subscriptionManagementInitialState,
  subscriptionReport: subscriptionReportInitialState,
  previewLetter: previewLetterStateInitialState,
  orderPreCheck: orderPreCheckInitialState,
  chOrderEntry: initialCHOrderEntryState,
  bot: {
    botRequest: botRequestInitialState
  },
  subscriptionLinksManagement: initialSubscriptionLinksManagementState,
};

export function createReducer(history: History): Reducer<RootStateType> {
  const reducer = combineReducers<RootStateType>({
    app: appSlice,
    product: combineReducers<ProductState>({
      details: productDetailsReducer,
      search: productSearchReducer,
      suggestions: productSuggestionsReducer,
      stocks: productStockReducer,
      upsells: productUpsellsReducer,
      stockReminder: stockReminderReducer,
      createWaitlistItem: createWaitlistItemReducer,
      createSubscription: createSubscriptionReducer,
    }),
    customer: combineReducers<CustomerState>({
      info: customerInfoSlice,
      search: customerSearchReducer,
      orderHistory: orderHistoryReducer,
      addressSuggestions: addressSuggestionsSlice,
      suggestions: customerSuggestionReducer,
      waitlistHistory: waitlistHistoryReducer,
      subscriptionHistory: subscriptionHistoryReducer,
      editSubscription: editSubscriptionReducer,
      pinVerification: customerPinReducer,
      newCustomer: newCustomerSlice,
    }),
    order: combineReducers<OrderState>({
      orderDisplay: orderDisplayReducer,
      newOrder: newOrderReducer,
      orderEntry: orderEntryReducer,
      voucher: voucherReducer,
      salesOffice: salesOfficeReducer,
      salesChannel: salesChannelReducer,
      sourceChannel: sourceChannelReducer,
    }),
    payment: paymentReducer,
    serviceVoucher: serviceVoucherReducer,
    router: connectRouter(history) as Reducer<RouterState | undefined, LocationChangeAction>,
    offers: offerReducer,
    notifications: notificationsTickerSlice,
    editOrder: editOrderSlice,
    inflightOrder: inflightOrderReducer,
    editedOrder: editedOrderReducer,
    recreateOrder: recreateOrderReducer,
    subscriptionManagement: subscriptionManagementReducer,
    subscriptionLinksManagement: subscriptionLinksManagementReducer,
    subscriptionReport: subscriptionReportReducer,
    previewLetter: previewLetterReducer,
    orderPreCheck: orderPreCheckReducer,
    chOrderEntry: chOrderEntryReducer,
    bot: combineReducers<BotState>({
      botRequest: botRequestReducer
    }),
  });

  return (state: RootStateType | undefined, action: AnyAction): RootStateType => {
    return reducer(state, action);
  };
}
