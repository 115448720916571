import { CountryCode } from 'src/api/orderTypes';
import {
  SubscriptionProduct,
  DeliveryProduct,
  MetaInfo,
} from 'src/subscriptionLinksManagement/types/subscriptionLinksManagement';


export interface SubscriptionProductLinkRequestWrapper {
  links: EditSubscriptionProductLinkRequest[];
}

export interface EditSubscriptionProductLinkRequest {
  id: string;
  subscriptionProduct: SubscriptionProduct;
  deliveryProduct: DeliveryProduct;
  status: SubscriptionProductLinkStatus;
  metaInfo: MetaInfo;
  regularProducts: string[];
  countriesExclusion?: CountryCode[];
}

export enum SubscriptionProductLinkStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
export enum SubscriptionLinksStatusRequest {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  STOPPED = 'STOPPED',
  DELETED = 'DELETED',
}
export enum FilterType {
  COUNTRY_EXCLUSION = 'COUNTRY_EXCLUSION',
  CREATION_DATE = 'CREATION_DATE',
  PREVIOUS_EDIT_DATE = 'PREVIOUS_EDIT_DATE',
  SEARCH_BY_KEYWORD = 'SEARCH_BY_KEYWORD',
  NONE = 'NONE'
}

export interface CountryExclusionConstraint {
  type: FilterType.COUNTRY_EXCLUSION;
  countryCode: CountryCode;
}
export interface CreationDateConstraint {
  type: FilterType.CREATION_DATE;
  startDate?: Date;
  endDate?: Date;
}
export interface PreviousEditDateConstraint {
  type: FilterType.PREVIOUS_EDIT_DATE;
  startDate?: Date;
  endDate?: Date;
}
export interface SearchByKeywordConstraint {
  type: FilterType.SEARCH_BY_KEYWORD;
  keyword?: string;
}

export type FilterSearchConstraint = CountryExclusionConstraint
  | CreationDateConstraint
  | PreviousEditDateConstraint
  | SearchByKeywordConstraint

export const SearchInputsTypeData = {
  COUNTRY_EXCLUSION: {
    value: 'COUNTRY_EXCLUSION' as FilterType,
    hasChild: false
  },
  CREATION_DATE: {
    value: 'CREATION_DATE' as FilterType,
    hasChild: true
  },
  PREVIOUS_EDIT_DATE: {
    value: 'PREVIOUS_EDIT_DATE' as FilterType,
    hasChild: true
  },
  NONE: {
    value: 'NONE' as FilterType,
    hasChild: false
  }
};
