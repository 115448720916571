
export enum Modals {
  address = 'CREATE_ADDRESS_MODAL',
  addressSuggestion = 'ADDRESS_SUGGESTION_MODAL',
  customer = 'CREATE_CUSTOMER_MODAL',
  customerWrongCountry = 'CUSTOMER_WRONG_COUNTRY',
  productDetails = 'PRODUCT_DETAILS_MODAL',
  productZoomedInImage = 'PRODUCT_ZOOMED_IN_IMAGE',
  bankingInfo = 'BANK_INFORMATION_MODAL',
  orderConfirmation = 'ORDER_CONFIRMATION',
  CHOrderConfirmation = 'CH_ORDER_CONFIRMATION',
  telephoneNotification = 'TELEPHONE_NOTIFICATION',
  orderHistory = 'ORDER_HISTORY',
  orderLimit = 'ORDER_LIMIT',
  cancelRejectedOrder = 'CANCEL_REJECTED_ORDER',
  productSearch = 'PRODUCT_SEARCH',
  customerSearch = 'CUSTOMER_SEARCH',
  voucherSuggestions = 'VOUCHER_SUGGESTIONS',
  installmentPayment = 'INSTALLMENT_PAYMENT',
  installmentFactoringPayment = 'INSTALLMENT_FACTORING_PAYMENT',
  orderNotEditable = 'ORDER_NOT_EDITABLE',
  editOrderLeaveConfirmation = 'EDIT_ORDER_LEAVE_CONFIRMATION',
  orderCancellationConfirmation = 'ORDER_CANCELLATION_CONFIRMATION',
  multipleDeliveryAddressChangeConfirmation = 'MULTIPLE_DELIVERY_ADDRESS_CHANGE_CONFIRMATION',
  consentManagement = 'CONSENT_MANAGEMENT',
  customerBlocked = 'CUSTOMER_BLOCKED',
  duplicatedEmail = 'DUPLICATED_EMAIL',
  duplicatedCustomer = 'DUPLICATED_CUSTOMER',
  privacyPolicy = 'PRIVACY_POLICY',
  birthdayCheck = 'BIRTHDAY_CHECK',
  addMissingBirthday = 'ADD_MISSING_BIRTHDAY',
  none = 'NONE',
  takeToOnlineShop = 'TAKE_TO_ONLINE_SHOP',
  takeBackDetailsCollection = 'TAKE_BACK_DETAILS_COLLECTION',
  takeBackDetails = 'TAKE_BACK_DETAILS',
  lastCustomersList = 'LAST_CUSTOMERS_LIST',
  rejectedInflightOrder = 'REJECTED_INFLIGHT_ORDER',
  waitlist = 'WAITLIST',
  receptionSurvey = 'RECEPTION_SURVEY',
  blockedKAT1Address = 'BLOCKED_KAT1_ADDRESS',
  WaitlistItemCancellation = 'WAITLIST_ITEM_CANCELLATION',
  SubscriptionCancellation = 'SUBSCRIPTION_CANCELLATION',
  EditSubscriptionModal = 'EDIT_SUBSCRIPTION_MODAL',
  subscription = 'SUBSCRIPTION',
  EditSubscriptionConfirmation = 'EDIT_SUBSCRIPTION_CONFIRMATION',
  editSubscriptionProductReportNote = 'EDIT_SUBSCRIPTION_PRODUCT_REPORT_NOTE',
  pinChallengeModal = 'PIN_CHALLENGE_MODAL',
  editSubscriptionDeliveryDateModal = 'EDIT_SUBSCRIPTION_DELIVERY_DATE_MODAL',
  EditSubscriptionProductNumberModal = 'EDIT_SUBSCRIPTION_PRODUCT_NUMBER_MODAL',
  editOrderInstallmentsFactoringPaymentModal = 'EDIT_ORDER_INSTALLMENTS_FACTORING_PAYMENT_MODAL',
  subscriptionCustomEventModal = 'SUBSCRIPTION_CUSTOM_EVENT_MODAL',
  chOrderHistoryModal = 'CH_ORDER_HISTORY_MODAL',
  subscriptionLinksSearchModal = 'SUBSCRIPTION_LINKS_SEARCH_MODAL',
  subscriptionLinksCreateModal = 'SUBSCRIPTION_LINKS_CREATE_MODAL',
  subscriptionLinksEditModal = 'SUBSCRIPTION_LINKS_EDIT_MODAL',
}
