import { AxiosInstance } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { xAuthorizationHeaderInterceptor } from 'src/api/httpClient/interceptors/xAuthorizationHeaderInterceptor';
import { BotRequestInfo } from 'src/types/bot/BotRequest';
import { InflightOrder } from 'src/types/inflightOrder/InflightOrder';
import { RingsTemplateRequest } from 'src/types/offer/InfoPostRequests';
import { EditedSubscriptionDeliveryDatesResponse } from 'src/types/subscription/EditedSubscriptionDeliveryDatesResponse';
import { EditMultipleSubscriptionNumbersRequest } from 'src/types/subscription/EditMultipleSubscriptionNumbersRequest';
import { EditSubscriptionRequest } from 'src/types/subscription/EditSubscriptionRequest';
import { EditSubscriptionDeliveryDatesRequest } from 'src/types/subscription/EditSubscriptionsDeliveryDatesRequest';
import { SubscriptionCustomEventRequest } from 'src/types/subscription/SubscriptionCustomEventRequest';
import { CancellationSubscriptionItemRequest, SubscriptionHistoryResponse } from 'src/types/subscription/SubscriptionHistoryResponse';
import { SubscriptionNoteRequest } from 'src/types/subscription/SubscriptionNoteRequest';
import { SubscriptionRequest } from 'src/types/subscription/SubscriptionRequest';
import { FetchSubscriptionsKeywordCriteriaType, SubscriptionStatusRequest } from 'src/types/subscription/SubscriptionsOverviewRequest';
import {
  CancellationWaitlistItemRequest,
  WaitlistItemRequest,
  WaitlistItemsListRequest
} from 'src/types/waitlist/CreateWaitlistItemRequest';
import { WaitlistHistoryItemsResponse } from 'src/types/waitlist/WaitlistHistoryResponse';

import { getHttpClient } from './httpClient/HttpClient';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';


export interface ApiCallCenter {
  client: AxiosInstance;
  getInflightOrder: (code: number) => Promise<InflightOrder>;
  createWaitlistItem: (requestBody: WaitlistItemRequest) => Promise<void>;
  getBotRequestByNumber: (requestNumber: string) => Promise<BotRequestInfo>;
  getCustomerWaitlistHistory: (requestBody: WaitlistItemsListRequest) => Promise<WaitlistHistoryItemsResponse>;
  cancelWaitlistItem: (request: CancellationWaitlistItemRequest) => Promise<void>;
  createSubscription: (requestBody: SubscriptionRequest) => Promise<void>;
  getFilteredSubscriptionsProducts: (externalCustomerId: string, keyword?: string, status?: SubscriptionStatusRequest, keywordType?: FetchSubscriptionsKeywordCriteriaType, limit?: number) => Promise<SubscriptionHistoryResponse>;
  editSubscription: (subscriptionId: string, requestBody: EditSubscriptionRequest) => Promise<void>;
  editMultipleSubscriptionNumbers: (requestBody: EditMultipleSubscriptionNumbersRequest) => Promise<void>;
  cancelSubscriptions: (request: CancellationSubscriptionItemRequest) => Promise<void>;
  sendRingsTemplateLetter: (request: RingsTemplateRequest) => Promise<void>;
  editSubscriptionNote: (subscriptionId: string, request: SubscriptionNoteRequest) => Promise<void>;
  editSubscriptionProductReport: (subscriptionProductReportId: string, request: SubscriptionNoteRequest) => Promise<void>;
  editSubscriptionsDeliveryDates: (req: EditSubscriptionDeliveryDatesRequest) => Promise<EditedSubscriptionDeliveryDatesResponse>;
  saveSubscriptionCustomEvent: (subscriptionId: string, request: SubscriptionCustomEventRequest) => Promise<void>;
}

export function getApiCallCenter(baseURL: string): ApiCallCenter {
  const callCenterClient = getHttpClient({
    baseUrl: baseURL,
    transform: {
      camelize: true,
    },
    interceptors: {
      response: refreshTokenInterceptor,
      request: [xAuthorizationHeaderInterceptor],
    },
  });

  return {
    client: callCenterClient,

    getInflightOrder: async function (code: number) {
      const axiosResponse = await callCenterClient.get<InflightOrder>(`/inflight-order?code=${code}`);
      return camelizeKeys(axiosResponse.data) as InflightOrder;
    },
    createWaitlistItem: async function (requestBody: WaitlistItemRequest) {
      const response = await callCenterClient.post('/waitlist-item', decamelizeKeys(requestBody));
      return response.data;
    },
    getBotRequestByNumber: async function (requestNumber: string) {
      const response = await callCenterClient.get(`/bot-request/${requestNumber}`);
      return response.data;
    },
    getCustomerWaitlistHistory: async (requestBody: WaitlistItemsListRequest) => {
      const response = await callCenterClient.post('/waitlist-items', decamelizeKeys(requestBody));
      return response?.data;
    },
    cancelWaitlistItem: async (request: CancellationWaitlistItemRequest) => {
      const response = await callCenterClient.patch(`/cancel-waitlist-item/${request.waitlistItemId}`, decamelizeKeys(request.cancellationReason));
      return response.data;
    },
    createSubscription: async (requestBody: SubscriptionRequest) => {
      const response = await callCenterClient.post('/subscription-product', decamelizeKeys(requestBody));
      return response.data;
    },
    getFilteredSubscriptionsProducts: async (externalCustomerId: string, keyword?: string, status?: SubscriptionStatusRequest, keywordType?: FetchSubscriptionsKeywordCriteriaType, limit?: number) => {
      const response = await callCenterClient.get(`/subscriptions-products/${externalCustomerId}`, {
        params: {
          keyword,
          status,
          keywordType,
          limit
        },
      });
      return response.data as SubscriptionHistoryResponse;
    },
    editSubscription: async (subscriptionId: string, requestBody: EditSubscriptionRequest) => {
      const response = await callCenterClient.patch(`/edit-subscription-product/${subscriptionId}`, decamelizeKeys(requestBody));
      return response.data;
    },
    editMultipleSubscriptionNumbers: async (requestBody: EditMultipleSubscriptionNumbersRequest) => {
      const response = await callCenterClient.patch('/edit-multiple-subscription-numbers', decamelizeKeys(requestBody));
      return response.data;
    },
    cancelSubscriptions: async (request: CancellationSubscriptionItemRequest) => {
      const response = await callCenterClient.patch('/cancel-subscriptions-products', decamelizeKeys(request));
      return response.data;
    },
    sendRingsTemplateLetter: async (request: RingsTemplateRequest) => {
      const response = await callCenterClient.post('/ringstemplate-send', decamelizeKeys(request));
      return response.data;
    },
    editSubscriptionNote: async (subscriptionId: string, request: SubscriptionNoteRequest) => {
      const response = await callCenterClient.patch(
        `/subscriptions/${subscriptionId}/edit-note`,
        decamelizeKeys(request),
      );
      return response.data;
    },
    editSubscriptionProductReport: async (subscriptionProductReportId: string, request: SubscriptionNoteRequest) => {
      const response = await callCenterClient.patch(
        `/subscription-product-management/products-report/${subscriptionProductReportId}/edit-note`,
        decamelizeKeys(request),
      );
      return response.data;
    },
    editSubscriptionsDeliveryDates: async (req: EditSubscriptionDeliveryDatesRequest) => {
      const response = await callCenterClient.patch(
        '/subscription-product-management/edit-delivery-dates',
        decamelizeKeys(req),
      );
      return response.data;
    },
    saveSubscriptionCustomEvent: async (subscriptionId: string, request: SubscriptionCustomEventRequest) => {
      const response = await callCenterClient.post(
        `/subscriptions/${subscriptionId}/events`,
        decamelizeKeys(request),
      );
      return response.data;
    },
  };
}
